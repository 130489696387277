const TIN_WHISTLE = "tin"
const HARP = "harp"

export const Song = [
{
    id:1,
    name: {en: "All my little ducklings", de:"Alle meine Entchen"},
    instrument: ["TinC", "TinD", "HarpC", "SaxoC"],
    bpm: 200,
    stroke: "4/4",
    key: "C",
    string: "c4/4;d4/4;e4/4;f4/4|g4/2;g4/2|a4/4;a4/4;a4/4;a4/4|g4/2;PA/2|a4/4;a4/4;a4/4;a4/4|g4/2;PA/2|f4/4;f4/4;f4/4;f4/4|e4/2;e4/2|g4/4;g4/4;g4/4;g4/4|c4/2",
    text: "A- lle Mei- ne Ent- chen schwim- men auf dem See, schwim- men auf dem See. Köpf- chen in das Was- ser, Schwänz- chen in die Höh'."
},
{id:3, name: "The Sally Gardens", instrument: ["TinC", "TinD", "HarpC", "SaxoC"], bpm: 120, stroke: "4/4", key: "D", string: "d4/8;e4/8|f#4/4;e4/8;d4/8;e4/4;f#4/8;a4/8|b4/2;a4/4;d5/8;a4/8|b4/4;a4/8;f#4/8;e4/4.;d4/8|d4/2.;d4/8;e4/8|f#4/4;e4/8;d4/8;e4/4;f#4/8;a4/8|b4/2;a4/4;d5/8;a4/8|b4/4;a4/8;f#4/8;e4/4.;d4/8|d4/2.;a4/4|d5/4;c#5/8;a4/8;b4/4;d5/4|c#5/4.;b4/8;a4/4;f#4/8;a4/8|b4/4;a4/8;f#4/8;a4/8;b4/8;d5/8;e5/8|d5/2.;d4/8;e4/8|a4/4;e4/8;d4/8;e4/4;f#4/8;a4/8|b4/2;a4/4;d5/8;a4/8|b4/4;a4/8;f#4/8;e4/4.;d4/8|d4/2"},
{id:4, name: "The Butcher Boy", instrument: ["TinC", "TinD", "HarpC", "SaxoC"], bpm: 120, stroke: "4/4", key: "D", string: "a4/4|a4/4;b4/4;a4/2|a4/2.+;g4/4|f#4/4;e4/4;d4/2|d4/2.+;f#4/4|f#4/4;f#4/4;g4/2|g4/2.+;e4/4|f#4/4;g4/4;a4/2|a4/2.+;a4/4|b4/4;c#5/4;d5/2|d5/2.+;a4/4|c#5/4;b4/4;e4/2|e4/2.+;a4/4|g4/4;b4/4;a4/2|a4/2.+;e4/4;f#4/4|g4/4;f#4/4;d4/2|d4/2+"},
{id:6, name: {en: "Zelda - Song of Storms", de: "Zelda - Hymne des Sturmes"}, instrument: ["TinC", "TinD", "HarpC"], bpm: 140, stroke: "3/4", key: "D", string: "d4/8;f#4/8;d5/2|d4/8;f#4/8;d5/2|e5/4.;f#5/8;e5/8;f#5/8|e5/8;c#5/8;a4/4;PA/4|a4/4;d4/4;f#4/8;g4/8|a4/2.|a4/4;d4/4;f#4/8;g4/8|e4/2.|d4/8;f#4/8;d5/2|d4/8;f#4/8;d5/2|e5/4.;f#5/8;e5/8;f#5/8|e5/8;c#5/8;a4/4;PA/4|a4/4;d4/4;f#4/8;g4/8|a4/2;a4/4|d4/2.|d4/2.+"},
{id:7, name: {en: "Zelda - Lost woods",de: "Zelda - Die verlorenen Wälder"}, instrument: ["TinC", "TinD", "HarpC"], bpm: 140, stroke: "4/4", key: "D", string: "g4/8;b4/8;c#5/4;g4/8;b4/8;c#5/4|g4/8;b4/8;c#5/8;f#5/8;e5/4;c#5/8;d5/8|c#5/8;a4/8;f#4/2;f#4/8+;e4/8|f#4/8;a4/8;f#4/2;f#4/8+;PA/8|g4/8;b4/8;c#5/4;g4/8;b4/8;c#5/4|g4/8;b4/8;c#5/8;f#5/8;e5/4;c#5/8;d5/8|f#5/8;c#5/8;a4/2;a4/8+;c#5/8|a4/8;e4/8;f#4/2;f#4/8+;PA/8|e4/8;f#4/8;g4/4;a4/8;b4/8;c#5/4|d5/8;c#5/8;f#4/2;f#4/8+;PA/8|e4/8;f#4/8;g4/4;a4/8;b4/8;c#5/4|d5/8;e5/8;f#5/2;f#5/8+;PA/8|e4/8;f#4/8;g4/4;a4/8;b4/8;c#5/4|d5/8;c#5/8;f#4/2;f#4/8+;PA/8|g4/8;f#4/8;b4/8;a4/8;c#5/8;b4/8;d5/8;c#5/8|e5/8;d5/8;f#5/8;e5/8;g5/8;f#5/8;f#5/16;g5/8;e5/16|f#5/1|PA/1"},
{id:8, name: {en: "Zelda's Lullaby", de: "Zeldas Wiegenlied"}, instrument: ["TinC", "TinD", "HarpC", "SaxoC"], bpm: 100, stroke: "3/4", key: "D", string: "f#4/2;a4/4|e4/2;d4/8;e4/8|f#4/2;a4/4|e4/2.|f#4/2;a4/4|e5/2;d5/4|a4/2;g4/8;f#4/8|e4/2.|f#4/2;a4/4|e4/2;d4/8;e4/8|f#4/2;a4/4|e4/2.|f#4/2;a4/4|e5/2;d5/4|a4/2.|a4/2;g4/8;f#4/8|g4/8;f#4/8;d4/2|g4/2;f#4/8;e4/8|f#4/8;e4/8;d4/2|a4/2;g4/8;f#4/8|g4/8;f#4/8;d4/4;g4/4|d5/2."},
{id:9, name: "Jingle Bells", instrument: ["TinC", "HarpC"], bpm: 200, stroke: "4/4", key: "G", string: "d4/4|d4/4;b4/4;a4/4;g4/4|d4/2.;d4/8;d4/8|d4/4;b4/4;a4/4;g4/4|e4/2.;e4/4|e4/4;c5/4;b4/4;a4/4|f4/2.;d5/4|e5/4;d5/4;c5/4;a4/4|b4/2.;d4/4|d4/4;b4/4;a4/4;g4/4|d4/2.;d4/4|d4/4;b4/4;a4/4;g4/4|e4/2.;e4/4|e4/4;c5/4;a4/4;a4/4|d5/4;d5/4;d5/4.;d5/8|e5/4;d5/4;c5/4;a4/4|g4/2.;PA/4|b4/4;b4/4;b4/2|b4/4;b4/4;b4/2|b4/4;d5/4;g4/4;a4/4|b4/1|c5/4;c5/4;c5/4;c5/4|c5/4;b4/4;b4/4.;b4/8|b4/4;a4/4;a4/4;b4/4|a4/2;d5/2|b4/4;b4/4;b4/2|b4/4;b4/4;b4/2|b4/4;d5/4;g4/4;a4/4|b4/1|c5/4;c5/4;c5/4;c5/4|c5/4;b4/4;b4/4.;b4/8|b4/4;a4/4;a4/4;b4/4|a4/2;d5/2|d5/4;d5/4;c5/4;a4/4|g4/2."},
{id:10, name: "Der Mond ist aufgegangen", instrument: ["TinC", "TinD", "HarpC", "SaxoC"], bpm: 120, stroke: "4/4", key: "C", string: "c4/4|d4/4;c4/4;f4/4;e4/4|d4/2;c4/4;e4/4|e4/4;e4/4;a4/4;g4/4|f4/2;e4/4;e4/4|e4/4;e4/4;f4/4;e4/4|d4/2;PA/4;c4/4|d4/4;c4/4;f4/4;e4/4|d4/2;c4/4;e4/4|e4/4;e4/4;a4/4;g4/4|f4/2;e4/4;e4/4|e4/4;e4/4;f4/4;e4/4|d4/4;d4/4;c4/4"},
{id:11, name: "Axel F (Beverly Hills Cop)", instrument: ["TinC", "TinD", "HarpC"], bpm: 130, stroke: "4/4", key: "C", string: "a4/4;c5/8.;a4/8;a4/16;d5/8;a4/8;g4/8|a4/4;e5/8.;a4/8;a4/16;f5/8;e5/8;c5/8|a4/8;e5/8;a5/8;a4/16;g4/8;g4/16;e4/8;b4/8;a4/8|a4/2+;PA/2"},
{id:12, name: "Nobody knows the trouble", instrument: ["TinC", "TinD", "HarpC", "SaxoC"], bpm: 100, stroke: "4/4", key: "D", string: "b4/8;d4/4;e4/8;g4/4;a4/4|b4/8;b4/4;b4/8;b4/2|b4/8;d4/4;e4/8;g4/4;g4/4|e4/8;d4/4.;PA/2|b4/8;d4/4;e4/8;g4/4;a4/4|b4/8;b4/4;b4/8;b4/2|d5/4.;c5/8;a4/4;b4/4|g4/2;g4/4;b4/4|d5/4;d5/4;d5/4.;c5/8|d5/4;d5/4;d5/8;b4/4.|d5/2;b4/2|a4/2;PA/4;b4/4|d5/4;d5/4;d5/4.;b4/8|d5/4;d5/4;d5/16;a4/16;g4/4.|b4/2;a4/2|g4/1"},
{id:13, name: {en: "Old McDonald had a farm", de: "Old McDonald hat ne' Farm"}, instrument: ["TinC", "TinD", "HarpC", "SaxoC"], bpm: 180, stroke: "4/4", key: "D", string: "g4/4;g4/4;d4/4;d4/4|e4/4;e4/4;d4/2|b4/4;b4/4;a4/4;a4/4|g4/2;PA/4;d4/4|g4/4;g4/4;d4/4;d4/4|e4/4;e4/4;d4/2|b4/4;b4/4;a4/4;a4/4|g4/2;PA/4;d4/8;d4/8|g4/4;g4/4;g4/4;d4/8;d4/8|g4/4;g4/4;g4/2|g4/8;g4/8;g4/4;g4/8;g4/8;g4/4|g4/8;g4/8;g4/8;g4/8;g4/4;g4/4|g4/4;g4/4;d4/4;d4/4|e4/4;e4/4;d4/2|b4/4;b4/4;a4/4;a4/4|g4/2.;PA/4"},
{id:14, name: "Go, tell it on the mountain", instrument: ["TinC", "TinD", "HarpC", "SaxoC"], bpm: 120, stroke: "4/4", key: "D", string: "b4/2;b4/8.;a4/16;g4/8.;e4/16|d4/2;g4/2|a4/8;a4/4;g4/8;a4/4;g4/8;a4/8|b4/4;g4/4;e4/8;d4/4.|b4/2;b4/8.;a4/16;g4/8.;e4/16|d4/2;g4/4;c5/4|b4/8;b4/4.;a4/4;a4/4|g4/2.;d4/4|a4/2;g4/4;a4/4|b4/8;g4/4.;PA/4;d4/4|a4/4.;a4/8;g4/4;a4/4|b4/4;d5/4;PA/4;d4/4|a4/4.;a4/8;g4/4;a4/4|b4/8;g4/4.;PA/4;c5/4|b4/4.;g4/8;a4/4.;a4/8|g4/1"},
{id:15, name: "Yankee Doodle", instrument: ["TinC", "TinD", "HarpC"], bpm: 200, stroke: "4/4", key: "C", string: "c5/4;c5/4;d5/4;e5/4|c5/4;c5/4;b4/4.;g4/8|c5/4;c5/4;d5/4;e5/4|c5/2;b4/4;g4/4|c5/4;c5/4;d5/4;e5/4|f5/4;e5/4;d5/4;c5/4|b4/4;g4/4;a4/4;b4/4|c5/2;c5/2|a4/4.;b4/8;a4/4;g4/4|a4/4;b4/4;c5/2|g4/4.;a4/8;g4/4;f4/4|e4/4;f4/4;g4/2|a4/4.;b4/8;a4/4;g4/4|a4/4;b4/4;c5/4;a4/4|g4/4;c5/4;a4/4;d5/4|c5/2;c5/2"},
{id:16, name: "Amazing Grace", instrument: ["TinC", "TinD", "HarpC", "SaxoC"], bpm: 100, stroke: "3/4", key: "D", string: "PA/2;d4/4|g4/2;b4/4|b4/2;g4/4|g4/2;e4/4|d4/2;d4/4|g4/2;b4/4|b4/2;a4/4|d5/2.|PA/2;b4/4|d5/2;b4/4|b4/2;a4/4|g4/2;e4/4|d4/2;d4/4|g4/2;b4/4|b4/2;a4/4|g4/2;PA/4"},
{id:17, name: "For he's a jolly good fellow", instrument: ["TinC", "TinD", "HarpC", "SaxoC"], bpm: 240, stroke: "3/4", key: "C", string: "PA/2;c4/4|e4/2;e4/4|e4/4;d4/4;e4/4|f4/2.|e4/2;e4/4|d4/2;d4/4|d4/4;c4/4;d4/4|e4/2.|c4/2;c4/4|e4/2;e4/4|e4/4;d4/4;e4/4|f4/2.|a4/2;a4/4|g4/4;g4/4;g4/4|f4/2;d4/4|c4/2.|c4/2+;PA/4"},
{id:18, name: "My Bonnie lies over the ocean", instrument: ["TinC", "TinD", "HarpC"], bpm: 150, stroke: "3/4", key: "C", string: "g4/4|e5/4;d5/4;c5/4|d5/4;c5/4;a4/4|g4/4;e4/2|PA/2;g4/4|e5/4;d5/4;c5/4|c5/4;b4/4;c5/4|d5/2.|PA/2;g4/4|e5/4;d5/4;c5/4|d5/4;c5/4;a4/4|g4/4;e4/2|PA/2;g4/4|a4/4;d5/4;c5/4|b4/4;g4/4;b4/4|c5/2.|c5/2.+|g4/2.|c5/2.|a4/2.|d5/2;c5/4|b4/4;b4/4;b4/4|b4/4;a4/4;b4/4|c5/2;d5/4|e5/2.|g4/2.|c5/2.|a4/2.|d5/2;c5/4|b4/4;b4/4;b4/4|b4/4;a4/4;b4/4|c5/2.|c5/4+;PA/4"},
{id:19, name: "Oh Susanna", instrument: ["TinC", "TinD", "HarpC", "SaxoC"], bpm: 120, stroke: "4/4", key: "C", string: "c4/8;d4/8|e4/4;g4/4;g4/4.;a4/8|g4/4;e4/4;c4/4;c4/8;d4/8|e4/4;e4/4;d4/4;c4/4|d4/2.;c4/8;d4/8|e4/4;g4/4;g4/4.;a4/8|g4/4;e4/4;c4/4;c4/8;d4/8|e4/4;e4/4;d4/4;d4/4|c4/2;PA/2|f4/2;f4/2|a4/4;a4/2;a4/4|g4/4;g4/4;e4/4;c4/4|d4/2.;c4/8;d4/8|e4/4;g4/4;g4/4.;a4/8|g4/4;e4/4;c4/4;c4/8;d4/8|e4/4;e4/4;d4/4;d4/4|c4/2;PA/4"},
{id:20, name: "Deck the halls", instrument: ["TinC", "TinD", "HarpC", "SaxoC"], bpm: 120, stroke: "4/4", key: "C", string: "g4/4.;f4/8;e4/4;d4/4|c4/4;d4/4;e4/4;c4/4|d4/8;e4/8;f4/8;d4/8;e4/4.;d4/8|c4/4;d4/4;c4/2|g4/4.;f4/8;e4/4;d4/4|c4/4;d4/4;e4/4;c4/4|d4/8;e4/8;f4/8;d4/8;e4/4.;d4/8|c4/4;d4/4;c4/2|d4/4.;e4/8;f4/4;d4/4|e4/4.;f4/8;g4/4;e4/4|e4/8;f4/8;g4/4;a4/8;b4/8;c5/4|b4/4;a4/4;g4/2|g4/4.;f4/8;e4/4;d4/4|c4/4;d4/4;e4/4;c4/4|a4/8;a4/8;a4/8;a4/8;g4/4.;f4/8|e4/4;d4/4;c4/2"},
{id:21, name: "Aura Lee", instrument: ["TinC", "TinD", "HarpC"], bpm: 120, stroke: "4/4", key: "C", string: "g4/4;c5/4;b4/4;c5/4|d5/4;d5/4;d5/2|c5/4;b4/4;a4/4;b4/4|c5/2.;PA/4|g4/4;c5/4;b4/4;c5/4|d5/4;a4/4;d5/2|c5/4;b4/4;a4/4;b4/4|c5/2.;PA/4|e5/4;e5/4;e5/2|e5/4;e5/4;e5/2|e5/4;d5/4;c5/4;d5/4|e5/2.;PA/4|e5/4;e5/4;f5/4;e5/4|d5/4;a4/4;d5/4.;c5/8|c5/4;b4/4;e5/4;d5/4|c5/2.;PA/4"},
{id:22, name: {en: "Auld lang syne", de: "Nehmt Abschied, Brüder"}, instrument: ["TinC", "TinD", "HarpC", "SaxoC"], bpm: 120, stroke: "4/4", key: "D", string: "d4/4|g4/4.;g4/8;g4/4;b4/4|a4/4.;g4/8;a4/4;b4/8;a4/8|g4/4.;g4/8;b4/4;d5/4|e5/2.;e5/4|d5/4.;b4/8;b4/4;g4/4|a4/4.;g4/8;a4/4;b4/8;a4/8|g4/4.;e4/8;e4/4;d4/4|g4/2;PA/4;e5/4|d5/4.;b4/8;b4/4;g4/4|a4/4.;g4/8;a4/4;e5/4|d5/4.;b4/8;b4/4;d5/4|e5/2.;e5/4|d5/4.;b4/8;b4/4;g4/4|a4/4.;g4/8;a4/4;b4/8;a4/8|g4/4.;e4/8;e4/4;d4/4|g4/2.;PA/4"},
{id:23, name: "Ma come balli bene bimba", instrument: ["TinC", "TinD", "HarpC"], bpm: 120, stroke: "3/4", key: "C", string: "g4/4|e5/8;d5/8;c5/8;b4/8;a4/8;g4/8|b4/4;b4/4;a4/8;g4/8|b4/4;b4/4;a4/8;g4/8|c5/4;c5/4;g4/4|e5/8;d5/8;c5/8;b4/8;a4/8;a4/8|b4/4;b4/4;a4/8;g4/8|b4/4;b4/4;a4/8;b4/8|c5/2;PA/4|a4/4;a4/4;b4/4|c5/8;b4/8;a4/2|c5/4;b4/4;a4/4|b4/4;e4/4;PA/4|a4/4;a4/4;b4/4|c5/8;b4/8;a4/2|c5/4;b4/4;a4/4|e5/2"},
{id:2, name: "House of the rising sun", instrument: ["TinC", "HarpC", "SaxoC"], bpm: 120, stroke: "3/4", key: "B", string: "d4/4|d4/2;e4/4|f4/2;a4/4|g4/8;d4/8;d4/2+|f4/2;d5/4|d5/2;d5/4|c5/4.;a4/4;PA/8|a4/2.|a4/2+;d5/4|d5/2;d5/4|c5/2;a4/4|g4/8;d4/4;d4/8;d4/4|f4/2;d4/4|d4/2;d4/4|g4/2;f4/4|d4/2.|d4/2+"},
{id:5, name: "When the saints go marchin' in", instrument: ["TinC", "TinD", "HarpC", "SaxoC"], bpm: 120, stroke: "4/4", key: "C", string: "c4/8;e4/8;f4/8|g4/2;PA/8;c4/8;e4/8;f4/8|g4/2;PA/8;c4/8;e4/8;f4/8|g4/4;e4/4;c4/4;e4/4|d4/2;PA/8;e4/8;e4/8;d4/8|c4/4.;c4/8;e4/4;g4/4|g4/8;f4/2;f4/8;e4/8;f4/8|g4/4;e4/4;c4/4;d4/4|c4/2;PA/8"},
{id:26, name: "She'll be coming round the mountain", instrument: ["TinC", "TinD", "HarpC", "SaxoC"], bpm: 120, stroke: "4/4", key: "D", string: "d4/4;d4/4|g4/8;g4/4.;g4/4.;g4/8|e4/4;d4/4;e4/4;d4/4|g4/1|g4/4+;PA/4;g4/4;a4/4|b4/8;b4/4.;b4/4.;b4/8|d5/4;b4/4;a4/4;g4/4|a4/1|a4/4+;PA/4;d5/4;c5/4|b4/8;b4/4.;b4/4.;b4/8|a4/4;g4/4;g4/4;g4/4|e4/8;e4/4.;e4/4.;e4/8|a4/4;g4/4;e4/4;e4/4|d4/8;d4/4.;g4/4;a4/4|b4/4;a4/4;b4/4;a4/4|g4/1|g4/4+;PA/4"},
{id:27, name: "Michael, row the boat ashore", instrument: ["TinC", "TinD", "HarpC", "SaxoC"], bpm: 120, stroke: "4/4", key: "C", string: "c4/8;e4/4.|g4/4.;e4/8;g4/4;a4/4|g4/2;e4/4;g4/4|a4/1|g4/2;e4/8;g4/4.|g4/4.;e4/8;f4/4;e4/4|d4/2;c4/4;d4/4|e4/2;d4/2|c4/2"},
{id:28, name: "Little brown jug", instrument: ["TinC", "TinD", "HarpC"], bpm: 120, stroke: "4/4", key: "C", string: "g4/4|e4/4;g4/4;g4/4;g4/4|f4/4;a4/4;a4/2|b4/8;b4/8;b4/8;b4/8;a4/4;b4/4|c5/4;d5/4;e5/2|e4/4;g4/4;g4/4;g4/4|f4/4;a4/4;a4/2|b4/4;b4/4;a4/4;b4/4|d5/4;c5/4;c5/2|e4/4;g4/4;g4/2|f4/4;a4/4;a4/2|b4/8;b4/8;b4/4;a4/4;b4/4|c5/4;d5/4;e5/2|e4/4;g4/4;g4/2|f4/4;a4/4;a4/2|b4/8;b4/8;b4/4;a4/4;b4/4|d5/4;c5/4;c5/4"},
{id:29, name: "Tom Dooley", instrument: ["TinC", "TinD", "HarpC", "SaxoC"], bpm: 120, stroke: "2/4", key: "D", string: "d4/8;d4/4;d4/8|e4/4;g4/4|b4/4;b4/4|b4/2+|d4/8;d4/4;d4/8|e4/4;g4/4|a4/2|a4/2+|d4/8;d4/4;d4/8|e4/4;g4/4|a4/4;a4/4|a4/2+|a4/8;a4/4;b4/8|g4/4;e4/4|g4/2"},
{id:30, name: "Swing low, sweet chariot", instrument: ["TinC", "TinD", "HarpC", "SaxoC"], bpm: 120, stroke: "4/4", key: "C", string: "a4/8;f4/8;f4/2+;a4/4|f4/4.;f4/8;d4/8;c4/4.|f4/8;f4/8;f4/8;f4/8;a4/8;a4/8;c5/4|c5/2;PA/2|d5/8;c5/8;a4/2;c5/4|f4/4.;f4/8;d4/8;c4/4.|f4/8;f4/8;f4/8;f4/8;a4/8;a4/8;e4/4|f4/2.;a4/4|c5/8;f4/4;d4/8;f4/8;f4/4;f4/8|f4/8;f4/8;f4/4;d4/8;c4/4.|f4/8;f4/8;f4/8;f4/8;a4/8;a4/8;c5/4|c5/2;PA/4;c5/4|d5/8;c5/4;a4/8;a4/8;f4/4.|f4/8;f4/8;f4/8;f4/8;d4/8;c4/4.|f4/8;f4/8;f4/8;f4/8;a4/8;a4/8;e4/4|f4/1"},
{id:31, name: "Good night, Ladies", instrument: ["TinC", "TinD", "HarpC", "SaxoC"], bpm: 120, stroke: "4/4", key: "D", string: "b4/2;g4/2|d4/4;g4/2.|b4/2;g4/2|a4/4;a4/2.|b4/2;g4/2|c5/4;c5/2;c5/4|b4/8;b4/8;b4/4;a4/4;a4/4|g4/1|b4/4;a4/4;g4/4;a4/4|b4/4;b4/4;b4/2|a4/4;a4/4;a4/2|b4/4;d5/4;d5/2|b4/4;a4/4;g4/4;a4/4|b4/4;b4/4;b4/2|a4/4;a4/4;b4/4;a4/4|g4/1"},
{id:32, name: {en: "Twinkle, twinkle, litte Star", de: "Funkel, funkel, kleiner Stern"}, instrument: ["TinC", "TinD", "HarpC", "SaxoC"], bpm: 120, stroke: "4/4", key: "C", string: "c4/4;c4/4;g4/4;g4/4|a4/4;a4/4;g4/2|f4/4;f4/4;e4/4;e4/4|d4/4;d4/4;c4/2|g4/4;g4/4;f4/4;f4/4|e4/4;e4/4;d4/2|g4/4;g4/4;f4/4;f4/4|e4/4;e4/4;d4/2|c4/4;c4/4;g4/4;g4/4|a4/4;a4/4;g4/2|f4/4;f4/4;e4/4;e4/4|d4/4;d4/4;c4/2"},
{id:33, name: "Give me that old time religion", instrument: ["TinC", "TinD", "HarpC", "SaxoC"], bpm: 120, stroke: "4/4", key: "C", string: "c4/8;c4/8;d4/4|f4/2;f4/4.;f4/8|d4/8;c4/4.;f4/8;f4/8;f4/4|g4/2;g4/4.;a4/8|g4/8;f4/4.;f4/8;f4/8;g4/4|a4/2;a4/4.;a4/8|g4/8;f4/4.;f4/4+;d4/4|c4/8;f4/4;f4/8;f4/4+;g4/4|f4/4;c4/4;c4/8;c4/8;d4/4|f4/2;f4/4.;f4/8|d4/8;c4/4.;f4/8;f4/8;f4/4|g4/2;g4/4.;a4/8|g4/8;f4/4.;f4/8;f4/8;g4/4|a4/2;a4/4.;a4/8|g4/8;f4/4.;f4/4+;d4/4|c4/8;f4/4;f4/8;f4/4+;g4/4|f4/2;c4/4;d4/4|f4/4;f4/8;f4/8;f4/4;f4/4|d4/4;c4/4;e4/4;f4/4|g4/4;g4/8;g4/8;g4/4;a4/4|g4/4;f4/4;f4/4;g4/4|a4/4;a4/8;a4/8;a4/4;a4/4|g4/4;f4/2;d4/8;d4/8|c4/8;f4/4;f4/8;f4/4+;g4/4|f4/2;c4/4;d4/4|f4/4;f4/8;f4/8;f4/4;f4/4|d4/4;c4/4;e4/4;f4/4|g4/4;g4/8;g4/8;g4/4;a4/4|g4/4;f4/4;f4/4;g4/4|a4/4;a4/8;a4/8;a4/4;a4/4|g4/4;f4/2;d4/8;d4/8|c4/8;f4/4;f4/8;f4/4+;g4/4|f4/2"},
{id:34, name: {en: "Ode to joy", de: "Ode an die Freude"}, instrument: ["HarpC"], bpm: 120, stroke: "4/4", key: "C", string: "e4/4;e4/4;f4/4;g4/4|g4/4;f4/4;e4/4;d4/4|c4/4;c4/4;d4/4;e4/4|e4/4.;d4/8;d4/2|e4/4;e4/4;f4/4;g4/4|g4/4;f4/4;e4/4;d4/4|c4/4;c4/4;d4/4;e4/4|d4/4.;c4/8;c4/2|d4/4;d4/4;e4/4;c4/4|d4/4;e4/8;f4/8;e4/4;c4/4|d4/4;e4/8;f4/8;e4/4;d4/4|c4/4;d4/4;g3/4;e4/4|e4/4+;e4/4;f4/4;g4/4|g4/4;f4/4;e4/4;f4/8;d4/8|c4/4;c4/4;d4/4;e4/4|d4/4.;c4/8;c4/2"},
{id:35, name: "Wayfaring stranger", instrument: ["TinC", "TinD", "HarpC"], bpm: 120, stroke: "4/4", key: "B", string: "d4/4;d4/4;a4/4|a4/1|a4/4+;g4/4;a4/4;g4/4|f4/4;d4/2.|d4/4;d4/4;d4/4;a4/4|g4/1|g4/4+;d4/4;f4/4;g4/4|a4/1|a4/4+;d4/4;d4/4;a4/4|a4/1|a4/4+;g4/4;a4/4;g4/4|f4/4;d4/2.|d4/4;d4/4;d4/4;a4/4|g4/1|g4/4+;f4/4;d4/4;c4/4|d4/1|d4/4+;a4/4;a4/4;c5/4|d5/1|d5/4+;c5/4;d5/4;c5/4|a4/4;g4/2.|g4/4;a4/4;a4/4;c5/4|d5/1|d5/4+;c5/4;e5/8;d5/8;c5/4|a4/1|a4/4+;a4/4;d5/4;c5/4|a4/1|a4/4+;f4/4;g4/4;a4/4|f4/4;d4/2.|d4/4+;d4/4;d4/4;a4/4|g4/1|g4/4+;f4/4;d4/4;c4/4|d4/1|d4/4+"},
{id:36, name: "Buffalo Gals", instrument: ["TinC", "TinD", "HarpC", "SaxoC"], bpm: 120, stroke: "4/4", key: "C", string: "g4/4|c4/4;c4/4;e4/4;g4/4|a4/4;g4/4;e4/2|g4/4;f4/4;d4/2|a4/4;g4/4;e4/4.;g4/8|c4/4;c4/4;e4/4;g4/4|a4/4;g4/4;e4/2|g4/4;g4/8;g4/8;f4/8;e4/8;d4/4|c4/2.;PA/4|c5/8;c5/8;b4/4;a4/4;g4/8;g4/8|a4/8;g4/4;g4/8;e4/2|g4/8;f4/4;f4/8;d4/2|a4/8;g4/4;g4/8;e4/2|c5/8;c5/8;b4/4;a4/4;g4/8;g4/8|a4/8;g4/4;g4/8;e4/4.;e4/8|g4/4;g4/8;g4/8;f4/8;e4/8;d4/4|c4/2."},
{id:37, name: {en: "Brahms' Lullaby", de: "Guten Abend, gute Nacht"}, instrument: ["TinC", "TinD", "HarpC", "SaxoC"], bpm: 120, stroke: "3/4", key: "C", string: "e4/8;e4/8|g4/4.;e4/8;e4/4|g4/2;e4/8;g4/8|c5/4;b4/4.;a4/8|a4/4;g4/4;d4/8;e4/8|f4/4;d4/4;d4/8;e4/8|f4/4;PA/4;d4/8;f4/8|b4/8;a4/8;g4/4;b4/4|c5/4;PA/4;c4/8;c4/8|c5/2;a4/8;f4/8|g4/2;e4/8;c4/8|f4/4;g4/4;a4/4|g4/2;c4/8;c4/8|c5/2;a4/8;f4/8|g4/2;e4/8;c4/8|f4/4;e4/4;d4/4|c4/2"},
{id:38, name: "Morning has broken", instrument: ["TinC", "TinD", "HarpC", "SaxoC"], bpm: 120, stroke: "3/4", key: "C", string: "c4/4;e4/4;g4/4|c5/2.|d5/2.|b4/4;a4/4;g4/4|a4/4.;b4/8;a4/4|g4/2;PA/4|c4/4;d4/4;e4/4|g4/2.|a4/2.|g4/4.;d4/8;e4/4|d4/2.|PA/2.|g4/4;e4/4;g4/4|c5/2.|a4/2.|g4/4;e4/4;c4/4|c4/4.;d4/8;c4/4|d4/2.|g4/4;e4/4;g4/4|g4/2.|a4/2.|d4/4.;e4/8;d4/4|c4/2.|c4/2+;PA/4"},
{id:39, name: "Kum ba yah, my lord", instrument: ["TinC", "TinD", "HarpC", "SaxoC"], bpm: 80, stroke: "3/4", key: "C", string: "c4/8;e4/8|g4/8.;g4/16;g4/4;a4/8;a4/8|g4/2;c4/8;e4/8|g4/8.;g4/16;g4/4;f4/8;e4/8|d4/2;c4/8;e4/8|g4/8.;g4/16;g4/4;a4/8;a4/8|g4/2;f4/4|e4/8;c4/8;d4/8;d4/8;c4/4|c4/2+"},
{id:40, name: "What shall we do with the drunken sailor", instrument: ["TinC", "TinD", "HarpC", "SaxoC"], bpm: 180, stroke: "4/4", key: "C", string: "a4/4;a4/8;a4/8;a4/4;a4/8;a4/8|a4/4;d4/4;f4/4;a4/4|g4/4;g4/8;g4/8;g4/4;g4/8;g4/8|g4/4;c4/4;e4/4;g4/4|a4/4;a4/8;a4/8;a4/4;a4/8;a4/8|a4/4;b4/4;c5/4;d5/4|c5/4;a4/4;g4/4;e4/4|d4/2;d4/2|a4/2;a4/4.;a4/8|a4/4;d4/4;f4/4;a4/4|g4/2;g4/4.;g4/8|g4/4;c4/4;e4/4;g4/4|a4/2;a4/4.;a4/8|a4/4;b4/4;c5/4;d5/4|c5/4;a4/4;g4/4;e4/4|d4/2;d4/2"},
{id:41, name: "Skip to my Lou", instrument: ["HarpC"], bpm: 120, stroke: "4/4", key: "C", string: "e4/4;e4/8;e4/8;c4/8;c4/8;c4/4|e4/4;e4/4;g4/2|d4/4;d4/8;d4/8;b3/8;b3/8;b3/4|d4/4;d4/4;f4/2|e4/4;e4/8;e4/8;c4/8;c4/8;c4/4|e4/4;e4/4;g4/2|d4/4;e4/8;f4/8;e4/4;d4/4|c4/2;c4/2|e4/2;c4/2|e4/4;e4/8;e4/8;g4/2|d4/2;b3/2|d4/4;d4/8;d4/8;f4/2|e4/2;c4/2|e4/4;e4/8;e4/8;g4/2|d4/4;e4/8;f4/8;e4/4;d4/4|c4/2;c4/2"},
{id:42, name: "Camptown Races", instrument: ["TinC", "TinD", "HarpC", "SaxoC"], bpm: 120, stroke: "4/4", key: "C", string: "g4/4|g4/4;g4/4;e4/4;g4/4|a4/4;g4/4;e4/2|e4/4;d4/2.|e4/4;d4/2;g4/4|g4/4;g4/4;e4/4;g4/4|a4/4;g4/4;e4/2|d4/2;e4/4;d4/4|c4/2;PA/4;g4/4|g4/4;g4/4;e4/4;g4/8;g4/8|a4/4;g4/4;e4/2|e4/4;d4/2.|e4/4;d4/2;g4/4|g4/4;g4/4;e4/4;g4/8;g4/8|a4/8;a4/8;g4/8;g4/8;e4/2|d4/2;e4/4;d4/4|c4/2.;PA/4|c4/4.;c4/8;e4/4;g4/4|c5/2;PA/2|a4/4.;a4/8;c5/4;a4/4|g4/2;PA/4;e4/4|g4/4;g4/4;e4/8;e4/8;g4/8;g4/8|a4/4;g4/4;e4/2|d4/4;e4/8;f4/8;e4/4;d4/8;d4/8|c4/2."},
{id:43, name: "Barbara Allen", instrument: ["TinC", "TinD", "HarpC", "SaxoC"], bpm: 50, stroke: "3/4", key: "C", string: "c4/8|e4/8;f4/8;g4/4.;f4/8|e4/8;d4/8;c4/4.;d4/8|e4/8;g4/8;c5/4;c5/4|b4/8;g4/4.;PA/8;b4/8|c5/8;a4/8;f4/4.;a4/8|g4/8;e4/8;c4/4.;d4/8|e4/8;g4/8;a4/4.;g4/8|e4/8;c4/4.;PA/8"},
{id:44, name: "John Brown's Body", instrument: ["TinC", "TinD", "HarpC"], bpm: 120, stroke: "4/4", key: "C", string: "g4/4;g4/8.;f4/16;e4/8.;g4/16;c5/8.;d5/16|e5/8.;e5/16;e5/8.;d5/16;c5/2|a4/4;a4/8.;b4/16;c5/8.;b4/16;c5/8.;a4/16|g4/8.;a4/16;g4/8.;e4/16;g4/2|g4/4;g4/8.;f4/16;e4/8.;g4/16;c5/8.;d5/16|e5/8.;e5/16;e5/8.;d5/16;c5/4;c5/4|d5/4;d5/4;c5/4;b4/4|c5/2.;PA/4|g4/4.;f4/8;e4/8.;g4/16;c5/8.;d5/16|e5/2;c5/4;PA/4|a4/4.;b4/8;c5/8.;a4/16;c5/8.;a4/16|g4/2;e4/4;PA/4|g4/4.;f4/8;e4/8.;g4/16;c5/8.;d5/16|e5/2;c5/4;c5/4|d5/4;d5/4;c5/4;b4/4|c5/2;PA/4"},
{id:45, name: "Day-O (Banana Boat Song)", instrument: ["HarpC"], bpm: 120, stroke: "4/4", key: "C", string: "c5/4.;g4/8;g4/2+|b4/8;a4/8;g4/8;e4/8;e4/2+|c4/4;c4/8;e4/8;e4/8+;e4/4;c4/8|d4/8;d4/8;c4/4;e4/2|c5/4.;g4/8;g4/2+|b4/8;a4/8;g4/8;e4/8;e4/2+|c4/4;c4/8;e4/8;e4/8+;e4/4;c4/8|d4/8;d4/8;d4/4;c4/2|e4/4;g4/8;g4/8;e4/8;e4/8;g4/4|f4/8;f4/8;f4/8;d4/8;b3/4;g3/4|c4/4;c4/8;e4/4;e4/4;c4/8|d4/8;d4/8;c4/4;e4/8;g4/4.|e4/4;g4/8;g4/8;e4/8;e4/8;g4/4|f4/8;f4/8;f4/8;d4/8;b3/4;g3/4|c4/4;c4/8;e4/4;e4/4;c4/8|d4/8;d4/8;d4/4;c4/4;g4/4|e4/4;g4/4;e4/8;e4/8;g4/4|e4/4;g4/4;c5/4;PA/4|c4/4;c4/8;e4/4;e4/4;c4/8|d4/8;d4/8;c4/4;e4/4;g4/4|e4/4;g4/4;e4/8;e4/8;g4/4|e4/4;g4/4;c5/4;PA/4|c4/4;c4/8;e4/4;e4/4;c4/8|d4/8;d4/8;d4/4;c4/2"},
{id:46, name: "Im Märzen der Bauer", instrument: ["HarpC"], bpm: 120, stroke: "3/4", key: "C", string: "g3/4|c4/4;c4/4;e4/4|d4/4;d4/4;f4/4|b3/4;b3/4;d4/4|c4/2;g3/4|c4/4;c4/4;e4/4|d4/4;d4/4;f4/4|b3/4;b3/4;d4/4|c4/2;g4/4|d4/4;d4/4;g4/4|e4/4;e4/4;g4/4|f4/4;f4/4;g4/4|e4/2;g3/4|c4/4;c4/4;e4/4|d4/4;d4/4;f4/4|b3/4;b3/4;d4/4|c4/2"},
{id:47, name: "Dat du min Leewesten büst", instrument: ["HarpC"], bpm: 120, stroke: "3/4", key: "C", string: "c4/4;e4/4;g4/4|d4/4;d4/8;b3/8;g3/4|c4/4;e4/4;g4/4|d4/2;PA/4|f4/4;a4/8;g4/8;f4/4|e4/4;g4/8;f4/8;e4/4|d4/4;c4/4;d4/4|e4/4;g4/2|f4/4;a4/8;g4/8;f4/4|e4/4;g4/8;f4/8;e4/4|d4/4;c4/4;b3/4|c4/2;PA/4"},
{id:48, name: "Winter Ade", instrument: ["TinC", "TinD", "HarpC", "SaxoC"], bpm: 120, stroke: "3/4", key: "C", string: "e4/4;e4/4;d4/4|c4/2.|e4/4;e4/4;d4/4|c4/2.|e4/4;f4/4;g4/4|g4/4;f4/8;e4/8;f4/4|d4/4;e4/4;f4/4|f4/4;e4/8;d4/8;e4/4|e4/4;e4/4;f4/4|g4/2.|e4/4;e4/4;f4/4|c4/2."},
{id:49, name: "Guter Mond, du gehst so stille", instrument: ["TinC", "TinD", "HarpC", "SaxoC"], bpm: 100, stroke: "4/4", key: "C", string: "g4/8;f4/8|e4/4;e4/4;e4/8;f4/8;g4/8;a4/8|g4/8;f4/8;d4/4;PA/4;g4/8;f4/8|e4/4;e4/4;d4/8;c4/8;d4/8;e4/8|c4/2;PA/4;g4/8;f4/8|e4/4;e4/4;e4/8;f4/8;g4/8;a4/8|g4/8;f4/8;c4/4;PA/4;g4/8;f4/8|e4/4;e4/4;d4/8;c4/8;d4/8;e4/8|c4/2;PA/4;d4/8;e4/8|f4/4;d4/4;PA/4;e4/8;f4/8|g4/4;e4/4;PA/4;g4/8;g4/8|a4/4.;g4/8;a4/8;g4/8;f4/8;e4/8|e4/4;d4/2;g4/8;f4/8|e4/4;e4/4;e4/8;f4/8;g4/8;a4/8|g4/8;f4/8;d4/4;PA/4;g4/8;f4/8|e4/4;e4/4;d4/8;c4/8;d4/8;e4/8|c4/2;PA/4"},
{id:50, name: "Kommt ein Vogel geflogen", instrument: ["HarpC"], bpm: 120, stroke: "3/4", key: "C", string: "e4/8;f4/8|g4/4;e4/4;e4/4|e4/4;d4/4;d4/8;e4/8|f4/4;d4/4;d4/8;a4/8|g4/2;e4/8;f4/8|g4/4;e4/4;e4/4|e4/4;d4/4;d4/8;e4/8|f4/4;b3/4;b3/8;b3/8|c4/2"},
{id:51, name: "Muss i denn zum Städele hinaus", instrument: ["TinC", "TinD", "HarpC", "SaxoC"], bpm: 120, stroke: "4/4", key: "C", string: "c4/8;d4/8|e4/4;e4/8;g4/8;f4/4;f4/8;a4/8|g4/4;g4/8;f4/8;e4/2|g4/4;g4/8;f4/8;e4/4;e4/8;g4/8|f4/4;f4/4;d4/4;g4/4|e4/2;PA/4;c4/8;d4/8|e4/4;e4/8;g4/8;f4/4;f4/8;a4/8|g4/4;g4/8;f4/8;e4/2|g4/4;g4/8;f4/8;e4/4;e4/8;g4/8|f4/4;f4/4;d4/4;a4/4|e4/2;PA/4;c4/8;e4/8|d4/4.;e4/8;f4/4;d4/4|e4/4.;f4/8;g4/4;g4/8;g4/8|a4/4;a4/4;c5/4;b4/8;a4/8|g4/2;PA/4;c4/8;e4/8|g4/4;g4/8;a4/8;g4/4;g4/8;c5/8|g4/4;g4/8;f4/8;e4/2|g4/4;g4/8;f4/8;e4/4;e4/8;g4/8|f4/4;f4/4;d4/4;g4/4|e4/2;PA/4"},
{id:52, name: "Wenn ich ein Vöglein wär", instrument: ["HarpC"], bpm: 120, stroke: "3/4", key: "C", string: "c4/4;c4/4;c4/4|e4/4.;d4/8;c4/4|e4/4;e4/4;e4/4|g4/4.;f4/8;e4/4|g4/4;f4/4;e4/4|d4/2;PA/4|d4/2;c4/8;b3/8|c4/4;d4/4;e4/4|f4/2;e4/8;d4/8|f4/4;f4/4;g4/4|g4/8;f4/8;e4/4;d4/4|c4/2;PA/4"},
{id:53, name: "Weißt du, wieviel Sternlein stehen", instrument: ["TinC", "TinD", "HarpC", "SaxoC"], bpm: 120, stroke: "3/4", key: "C", string: "c4/8;d4/8|e4/4;e4/4;f4/8;d4/8|a4/8;g4/8;g4/4;e4/8;g4/8|g4/8;f4/8;f4/4;g4/8;f4/8|e4/2;c4/8;d4/8|e4/4;e4/4;f4/8;d4/8|a4/8;g4/8;g4/4;e4/8;g4/8|g4/8;f4/8;f4/4;g4/8;f4/8|e4/2;g4/8;e4/8|e4/8;d4/8;d4/4;a4/8;f4/8|f4/8;e4/8;e4/4;g4/8;e4/8|e4/8;d4/8;d4/4;a4/8;f4/8|f4/8;e4/8;e4/4;c4/8;d4/8|e4/4;e4/4;f4/8;d4/8|g4/4;g4/4;e4/8;g4/8|g4/8;f4/8;f4/4;g4/8;f4/8|e4/2"},
{id:54, name: "Alle Vögel sind schon da", instrument: ["TinC", "TinD", "HarpC", "SaxoC"], bpm: 120, stroke: "4/4", key: "C", string: "c4/4.;e4/8;g4/4;c5/4|a4/4;c5/8;a4/8;g4/2|f4/4.;g4/8;e4/4;c4/4|d4/2;c4/4;PA/4|g4/4;g4/4;f4/4;f4/4|e4/4;g4/8;e4/8;d4/2|g4/4;g4/4;f4/4;f4/4|e4/4;g4/8;e4/8;d4/2|c4/4.;e4/8;g4/4;c5/4|a4/4;c5/8;a4/8;g4/2|f4/4.;g4/8;e4/4;c4/4|d4/2;c4/4;PA/4"},
{id:55, name: "Bald gras ich am Neckar", instrument: ["TinC", "TinD", "HarpC", "SaxoC"], bpm: 120, stroke: "3/4", key: "C", string: "e4/8;f4/8|e4/4;d4/4;d4/4|c4/4;e4/4;e4/4|g4/4;d4/4;d4/4|g4/2;e4/8;f4/8|e4/4;d4/4;d4/4|c4/4;e4/4;e4/4|g4/4;d4/4;d4/8;e4/8|c4/2"},
{id:56, name: "He's got the whole world in his hands", instrument: ["HarpC"], bpm: 120, stroke: "4/4", key: "C", string: "g4/8;g4/8;e4/8|g4/2;e4/8;c4/4.|c4/8+;g4/8;a4/8;g4/4;g4/8;g4/8;e4/8|f4/2;d4/8;b3/4.|b3/8+;g4/8;a4/8;g4/4;g4/8;g4/8;e4/8|g4/2;e4/8;c4/4.|c4/8+;g4/8;a4/8;g4/4;g4/8;g4/8;e4/8|g4/4;g4/4;f4/8;d4/4.|c4/2;PA/8"},
{id:57, name: "Die Vogelhochzeit", instrument: ["HarpC"], bpm: 100, stroke: "2/4", key: "C", string: "e4/8|g4/8;e4/8;g4/8;e4/8|f4/8;d4/8;f4/8;d4/8|e4/8;c4/8;g4/8;e4/8|d4/8;g4/8;g4/8;g4/16;g4/16|c4/8;c4/8;c4/8;e4/16;e4/16|d4/8;d4/8;d4/8;f4/16;f4/16|e4/8;c4/8;d4/8;b3/8|c4/4;PA/8"},
{id:58, name: "Sabinchen war ein Frauenzimmer", instrument: ["HarpC"], bpm: 120, stroke: "3/4", key: "C", string: "g3/8|c4/4;c4/8;c4/4;c4/8|c4/4;e4/8;g4/8;e4/8;c4/8|d4/4;d4/8;g4/4;g4/8|c4/4.;PA/4;g3/8|c4/4;c4/8;c4/4;c4/8|c4/4;e4/8;g4/8;e4/8;c4/8|d4/4;d4/8;e4/4;e4/8|c4/4.;PA/4;g4/8|g4/4;g4/8;a4/4;g4/8|e4/8;c4/8;PA/4.;g4/8|g4/4;g4/8;a4/4;g4/8|e4/4.;PA/4;g3/8|c4/4;c4/8;c4/4;c4/8|c4/8;c4/8;e4/8;g4/8;e4/8;c4/8|d4/4;d4/8;g4/4;g4/8|c4/4;PA/4"},
{id:59, name: "Spinn, spinn, meine liebe Tochter", instrument: ["TinC", "TinD", "HarpC", "SaxoC"], bpm: 120, stroke: "3/4", key: "C", string: "g4/4|c5/4;c5/8;b4/8;c5/8;a4/8|g4/4;e4/4;g4/4|f4/4;d4/4;a4/4|g4/4;PA/4;g4/4|c5/4;c5/8;b4/8;c5/8;a4/8|g4/4;e4/4;g4/4|f4/4;d4/4;g4/4|c4/4;PA/4;e4/4|e4/4;e4/4;e4/4|f4/4;f4/4;f4/4|d4/4;d4/4;d4/4|g4/4;g4/4;g4/4|c5/2;g4/4|c5/2;g4/4|f4/4;e4/4;d4/4|c4/2"},
{id:60, name: "Komm lieber Mai", instrument: ["TinC", "TinD", "HarpC", "SaxoC"], bpm: 120, stroke: "3/4", key: "C", string: "c4/8|c4/4;e4/8;g4/4;c5/8|g4/4.;e4/4;c4/8|f4/4;f4/8;f4/8;g4/8;f4/8|e4/2;PA/8;c4/8|c4/4;e4/8;g4/4;c5/8|g4/4.;e4/4;c4/8|d4/4;d4/8;d4/8;e4/8;d4/8|c4/4.;PA/4;e4/8|f4/4;e4/8;d4/8;e4/8;f4/8|g4/4.;e4/4;c5/8|c5/8;b4/8;a4/8;a4/8;g4/8;a4/8|g4/2;PA/8;c4/8|c4/4;e4/8;g4/4;c5/8|c5/8;a4/8;f4/8;d4/4;a4/8|g4/8;e4/8;g4/8;f4/8;e4/8;d4/8|c4/4.;c4/4+"},
{id:61, name: "Herr Mannelig", instrument: ["TinD", "SaxoC"], bpm: 132, stroke: "4/4", key: "D", string: "b4/8|b4/8;c5/8;b4/8;a4/8;g4/4;e4/8;f#4/8|g4/8;a4/8;g4/8;f#4/8;e4/4;e4/8;f#4/8|g4/4;g4/8;g4/8;a4/4;g4/8;a4/8|b4/2;g4/4.;b4/8|b4/8;c5/8;b4/8;a4/8;g4/4;e4/8;f#4/8|g4/8;a4/8;g4/8;f#4/8;e4/4;e4/8;f#4/8|g4/2;a4/8.;a4/16;g4/8;a4/8|b4/2;g4/4.;b4/8|b4/8;c5/8;b4/8;a4/8;g4/8;g4/8;e4/8;f#4/8|g4/8;a4/8;g4/8;f#4/8;e4/4;e4/8;f#4/8|g4/2;a4/4;g4/8;a4/8|b4/2;d5/4;PA/8;b4/8|b4/8;c5/8;b4/8;a4/8;g4/4;e4/8;f#4/8|g4/8;a4/8;g4/8;f#4/8;e4/4;e4/8;f#4/8|g4/8;a4/8;a4/8+;g4/16;f#4/16;e4/2"},
{id:62, name: "The hanging tree", instrument: ["TinC", "TinD", "HarpC"], bpm: 90, stroke: "4/4", key: "C", string: "g4/8|a4/4;PA/8;c5/8;d5/4;PA/4|a4/8;a4/8;a4/8;g4/8;e4/4;PA/8;g4/8|a4/4;a4/8;c5/8;d5/4;PA/8;g4/8|a4/8;a4/8;a4/8;g4/8;a4/4;PA/4|e5/4;d5/8;c5/8;d5/8;c5/8;a4/8;g4/8|a4/8;a4/8;a4/8;g4/8;e4/4;PA/8;g4/8|a4/4;PA/8;c5/8;d5/4;PA/8;g4/8|a4/8;a4/8;a4/8;g4/8;a4/4;PA/8"},

{id:63, name: "Es waren zwei Königskinder", instrument: ["TinC", "TinD", "HarpC"], bpm: 120, stroke: "3/4", key: "C", string: "e4/8|e4/8;c4/8;c4/8;c4/8;d4/8;e4/8|e4/8;d4/8;PA/4.;d4/8|d4/8;d4/8;d4/8;g4/8;g4/8;f4/8|e4/4.;PA/4;c4/16;e4/16|g4/8;g4/8;g4/8;g4/8;g4/8;c5/8|a4/4.;a4/8+;g4/8;f4/8|e4/8;e4/8;e4/8;g4/4;f4/8|e4/4.;PA/4;c4/16;e4/16|g4/8;g4/8;g4/8;g4/8;g4/8;c5/8|a4/4.;a4/8+;g4/8;f4/8|e4/8;e4/8;e4/8;g4/4;f4/8|e4/4.;PA/4"},
{id:64, name: "Have you got good religion", instrument: ["TinC", "TinD", "HarpC"], bpm: 120, stroke: "4/4", key: "C", string: "PA/8;c5/4;a4/8|c5/4;c5/8;a4/8;c5/8;c5/8;c5/4+|a4/8;a4/8;a4/8;a4/8;a4/8+;c5/4;a4/8|c5/4;c5/8;a4/8;c5/8;c5/8;c5/4+|g4/8;g4/8;g4/8;g4/8;g4/8+;c5/4;a4/8|c5/4;a4/8;g4/8;c5/8;a4/8;g4/4|e4/8;e4/8;e4/8;e4/4;d4/8;c4/4|e4/8;e4/8;e4/4;c4/8;c4/8;c4/4|d4/8;d4/8;d4/8;c4/8;c4/2+|e4/8;e4/8;e4/4;c4/8;c4/8;c4/4|d4/8;d4/8;d4/8;c4/8;c4/2+|e4/8;e4/8;e4/4;d4/8;d4/8;d4/4|d4/8;d4/8;d4/8;e4/8;e4/2+"},
{id:65, name: "Stehn zwei Stern am hohen Himmel", instrument: ["TinC", "TinD", "HarpC"], bpm: 120, stroke: "4/4", key: "D", string: "d4/4;d4/4|e4/4;e4/8;d4/8;d4/4;b4/4|b4/8;a4/8;g4/4;d4/4;d4/4|e4/4;c5/8;a4/8;b4/4;a4/4|g4/2;d4/4;c5/8;b4/8|a4/2;d4/4;d5/8;c5/8|b4/2;d4/4;d4/4|e4/4;c5/8;a4/8;b4/4;a4/4|g4/2;d4/4;c5/8;b4/8|a4/2;d4/4;d5/8;c5/8|b4/2;d4/4;d4/4|e4/4;c5/8;a4/8;b4/4;a4/4|g4/2"},
{id:66, name: "Lang, lang ist's her", instrument: ["TinC", "TinD", "HarpC"], bpm: 120, stroke: "2/4", key: "D", string: "g4/4;g4/8;a4/8|b4/4;b4/8;c5/8|d5/4;e5/8;d5/8|b4/2|d5/4;c5/8;b4/8|a4/2|c5/4;b4/8;a4/8|g4/2|g4/4;g4/8;a4/8|b4/4;b4/8;c5/8|d5/4;e5/8;d5/8|b4/2|d5/4;c5/8;b4/8|a4/4;b4/8;a4/8|g4/2|g4/4+;PA/4|d5/4;c5/8;b4/8|a4/4;d4/8;d4/8|c5/4;b4/8;a4/8|g4/2|d5/4;c5/8;b4/8|a4/4;d4/4|c5/4;b4/8;a4/8|g4/2|g4/4;g4/8;a4/8|b4/4;b4/8;c5/8|d5/4;e5/8;d5/8|b4/2|d5/4;c5/8;b4/8|a4/4;b4/8;a4/8|g4/2|g4/4+;PA/4"},
{id:67, name: "Wach auf mein Herzens Schöne", instrument: ["TinC", "TinD", "HarpC"], bpm: 120, stroke: "4/4", key: "c", string: "c4/4|g4/4;g4/4;g4/4;g4/4|c5/2;g4/4;g4/4|a4/4;g4/4;f4/4;g4/4|e4/2;PA/4;c4/4|g4/4;g4/4;g4/4;g4/4|c5/2;g4/4;g4/4|a4/4;g4/4;f4/4;g4/4|e4/2;PA/4;g4/4|c5/4;b4/8;b4/8;a4/4;g4/4|a4/2;g4/4;g4/4|a4/4;a4/4;b4/4;b4/4|c5/4;c5/4;d5/4;g4/4|c5/4;a4/4;g4/4;f4/4|d4/2;c4/4"},
{id:68, name: "Am Brunnen vor dem Tore", instrument: ["TinC", "TinD", "HarpC"], bpm: 120, stroke: "3/4", key: "C", string: "g4/4|g4/4.;e4/8;e4/8;e4/8|e4/4;c4/4.;c4/8|d4/4.;e4/8;f4/83;e4/83;d4/83|e4/2;PA/8;g4/8|g4/4.;e4/8;e4/8;e4/8|e4/4;c4/4.;c4/8|d4/4.;e4/8;g4/83;f4/83;d4/83|c4/2;PA/8;c4/8|d4/4.;d4/8;d4/8;d4/8|e4/8.;f4/16;g4/4;PA/8;g4/8|a4/4.;g4/8;e4/8;c4/8|d4/2;PA/8;d4/8|d4/4.;d4/8;d4/8;d4/8|e4/8.;f4/16;g4/4;PA/8;g4/8|c5/4;g4/8;e4/8;f4/8;d4/8|g4/2;PA/8;g4/8|c5/4;g4/8;e4/8;g4/83;e4/83;d4/83|c4/2"},
{id:69, name: "Du, du liegst mir am Herzen", instrument: ["TinC", "TinD", "HarpC"], bpm: 120, stroke: "3/4", key: "C", string: "e4/2.|e4/2.|e4/4;d4/4;e4/4|g4/2;f4/4|d4/2.|d4/2.|d4/4;c4/4;d4/4|e4/2;PA/4|e4/2.|e4/2.|e4/4;d4/4;e4/4|g4/2;f4/4|d4/4;e4/4;f4/4|a4/4;g4/4;b3/4|c4/2.|PA/2.|a4/2.|g4/2.|b4/2.|c5/2;PA/4|d4/4;e4/4;f4/4|a4/4;g4/4;b3/4|c4/2."},
{id:70, name: "Es war ein König in Thule", instrument: ["TinC", "TinD", "HarpC"], bpm: 120, stroke: "3/4", key: "D", string: "b4/4|e5/2;e5/4|b4/4.;b4/8;b4/4|c5/2.|g4/2;g4/4|a4/2;b4/4|c5/2;a4/4|b4/2.|PA/2;b4/4|e5/2;e5/4|d5/2;d5/4|g4/2.|a4/2;a4/8;a4/8|b4/2;c5/4|a4/2;b4/4|e4/2.|e4/2+"},
{id:71, name: "Kein Schöner Land", instrument: ["TinC", "TinD", "HarpC"], bpm: 120, stroke: "3/4", key: "D", string: "d4/8;d4/8;d4/8|g4/4;b4/4;a4/8;g4/8|a4/4.;d4/8;d4/8;d4/8|g4/4;b4/4;a4/8;g4/8|a4/4;PA/8;b4/8;g4/8;a4/8|b4/8;d5/8;c5/8;b4/8;a4/8;g4/8|a4/8;c5/8;b4/8;a4/8;g4/8;a4/8|b4/4.;b4/8;g4/8;a4/8|b4/8;d5/8;c5/8;c5/8;a4/8;g4/8|a4/8;c5/8;b4/8;a4/8;g4/8;a4/8|e4/4."},
{id:72, name: "Mariechen saß weinend im Garten", instrument: ["TinC", "TinD", "HarpC"], bpm: 120, stroke: "3/4", key: "C", string: "g4/8|e4/8;e4/8;e4/8;a4/8;a4/8;a4/8|g4/8;g4/4;PA/4;g4/8|e4/8;e4/8;e4/8;g4/8;g4/8;g4/8|f4/4.;PA/4;g4/8|g4/8;g4/8;g4/8;b4/4;b4/8|a4/8;a4/4;PA/4;f4/8|f4/8;f4/8;f4/8;a4/4;a4/8|g4/4.;PA/4;g4/8|g4/4;c5/8;e5/4;d5/8|d5/8;c5/4;PA/4;c5/8|c5/4;c5/8;b4/4;c5/8|d5/4.;PA/4;c5/8|b4/4;a4/8;a4/4;g4/8|b4/8;b4/4;PA/4;a4/8|g4/4;b4/8;f4/4;g4/8|e4/4.;PA/4"},
{id:73, name: "Nun ade", instrument: ["TinC", "TinD", "HarpC"], bpm: 120, stroke: "4/4", key: "C", string: "c4/8;e4/8|g4/4;g4/4;g4/4;a4/4|g4/4.;e4/8;c4/4;a4/8;g4/8|g4/4.;f4/8;f4/4;g4/4|e4/2;PA/4;c4/8;e4/8|g4/4;g4/4;g4/4;a4/4|g4/4.;e4/8;c4/4;a4/8;g4/8|g4/4.;f4/8;f4/4;g4/4|e4/2;PA/4;c4/8;e4/8|d4/4;f4/4;f4/4;f4/4|e4/4.;g4/8;c5/4;b4/8;c5/8|d5/4;c5/4;b4/4;a4/4|a4/4.;g4/8;g4/4;a4/8;g4/8|g4/4.;f4/8;f4/4;g4/4|c4/2;PA/4"},
{id:74, name: "Schandmaul - Sturmnacht", instrument: ["TinC", "TinD", "HarpC"], bpm: 160, stroke: "4/4", key: "C", string: "f4/4;d4/8;c4/8;d4/4;f4/8;e4/8|e4/8+;d4/8;c4/8;d4/8;f4/8;d4/8;c4/8;d4/8|f4/4;d4/8;c4/8;d4/4;f4/8;e4/8|e4/8+;d4/8;c4/4;d4/2|f4/4;d4/8;c4/8;d4/4;f4/8;e4/8|e4/8+;d4/8;c4/8;d4/8;f4/8;d4/8;c4/8;d4/8|f4/4;d4/8;c4/8;d4/4;f4/8;e4/8|e4/8+;d4/8;c4/4;d4/2|d4/8;e4/8;f4/8;g4/8;a4/4;d5/8;c5/8|c5/8+;b4/8;g4/4;a4/2|c5/4;a4/8;g4/8;a4/4;c5/8;b4/8|b4/8+;a4/8;g4/8;a4/8;e4/4;d4/4|d4/8;e4/8;f4/8;g4/8;a4/4;d5/8;c5/8|c5/8+;b4/8;g4/4;a4/2|c5/4;a4/8;g4/8;a4/4;c5/8;b4/8|b4/8+;a4/8;g4/8;a4/8;e4/4;d4/4"},
{id:75, name: "La Paloma", instrument: ["TinC", "TinD", "HarpC"], bpm: 80, stroke: "2/4", key: "C", string: "g4/8|g4/2|g4/83+;e4/83;f4/83;g4/8;a4/8|b4/83;c5/83;a4/83;b4/8;g4/8|f4/2|f4/4+;PA/8;d5/8|d5/2|d5/83+;e5/83;c5/83;d5/8;b4/8|d5/83;c5/83;b4/83;g4/8;f4/8|e4/2|e4/4+;PA/8;g4/8|g4/2|g4/83+;e4/83;f4/83;g4/8;a4/8|b4/83;c5/83;a4/83;b4/8;g4/8|f4/2|f4/4+;PA/8;d5/8|d5/2|d5/83+;e5/83;c5/83;d5/8;b4/8|d5/83;c5/83;b4/83;g4/8;f4/8|e4/2|e4/4+;PA/8;g4/8|e4/2|e4/4+;PA/4|c5/83;c5/83;c5/83;c5/8;b4/8|d5/83;d5/83;d5/83;b4/8;a4/8|a4/8;g4/4.|g4/2+|b4/83;b4/83;b4/83;b4/8;a4/8|a4/83;g4/83;g4/83;g4/8;a4/8|g4/16;f4/16;e4/4.|e4/4+;PA/4|c5/83;c5/83;c5/83;c5/8;b4/8|d5/83;d5/83;d5/83;b4/8;a4/8|a4/8;g4/4.|g4/2+|b4/83;b4/83;b4/83;b4/8;a4/8|a4/83;g4/83;g4/83;g4/8;a4/8|g4/16;f4/16;e4/4.|e4/4+;PA/4|g4/16;f4/16;e4/4.|e4/83+;g4/83;g4/83;g4/83;a4/83;f4/83|g4/2|PA/83;g4/83;g4/83;g4/83;g4/83;a4/83|b4/4;d5/4|d5/83+;e5/83;c5/83;d5/83;b4/83;c5/83|a4/83;b4/83;c5/83;e5/8;d5/8|d4/83;e4/83;f4/83;a4/8;g4/8|e4/2|e4/83+;g4/83;g4/83;g4/83;a4/83;f4/83|g4/2|PA/83;g4/83;g4/83;g4/83;g4/83;a4/83|b4/4;d5/4|d5/83+;f5/83;e5/83;d5/83;e5/83;c5/83|d5/83;b4/83;c5/83;e5/8;d5/8|d4/83;e4/83;f4/83;a4/8;g4/8|c5/4;PA/4"},

//{id:76, name: "", instrument: ["TinC", "TinD", "HarpC"], bpm: 120, stroke: "4/4", key: "C", string: ""},
//{id:77, name: "", instrument: ["TinC", "TinD", "HarpC"], bpm: 120, stroke: "4/4", key: "C", string: ""},
//{id:78, name: "", instrument: ["TinC", "TinD", "HarpC"], bpm: 120, stroke: "4/4", key: "C", string: ""},
//{id:79, name: "", instrument: ["TinC", "TinD", "HarpC"], bpm: 120, stroke: "4/4", key: "C", string: ""},
//{id:80, name: "", instrument: ["TinC", "TinD", "HarpC"], bpm: 120, stroke: "4/4", key: "C", string: ""},
]