<template>
    <div class="audiobuttons">
      <button class="button" id="playBtn" v-on:click="play()"><i class="fa fa-play" aria-hidden="true"></i></button>
      <button class="button" id="resetBtn" v-on:click="onResetBtn()"><i class="fa fa-step-backward" aria-hidden="true"></i></button>
      <input type="range" min="20" max="150" value="100" step="5" class="slider is-fullwidth" v-on:input="updateSpeedSlider()" id="speedSlider"><span id='speedValue'>Speed: 100%</span>
      <label class="switch">
        <input type="checkbox"  v-on:click="onMusicSwitch()" checked="checked" id="soundCheckBox">
        <span class="switch-slider"></span>
      </label>
      <i class="fas fa-music" id="soundCheckIcon"></i>
      <label id="loopLabel" class="switch">
        <input type="checkbox" v-on:click="onLoopSwitch()" checked="checked" id="loopCheckBox">
        <span class="switch-slider"></span>
      </label>
      <i class="fas fa-undo-alt" id="loopCheckIcon"></i>
  </div>
</template>

<script>
import {Save} from '../save'
import {
  onMusicSwitch, 
  onLoopSwitch, 
  updateSpeedSlider,
  config,
  playSong,
  onResetBtn
  } from '../main'

export default {
    name: 'audiobuttons',
    methods: {
    play() {
      config.speed = document.getElementById("speedSlider").value
      Save.setLastLearnedSongConfig(config)
      playSong()
    },
    onMusicSwitch,
    onLoopSwitch,
    updateSpeedSlider,
    onResetBtn
  }
}
</script>