import {Song} from './songs'
var note2fftPos = function(note, key = "C") {
    var id = 0
    if (note === "PA") return true
    function get(harp, harpNotes) {
    	if (isNaN(note)) {
    		id = harpNotes.indexOf(note)
	    } else {
	    	id = note > 0 ? note - 1 : Math.abs(note) + 9
	    }
    	return harp[id]
    }
    switch (key) {
        case "C":
            return get(HarpKeyC, HarpKeyCNotes)
        default:
            return get(HarpKeyC, HarpKeyCNotes)
    }

}

export function getHarpsForKey(key) {
	switch (key) {
        case "C":
            return {harp: HarpKeyC, harpNotes: HarpKeyCNotes}
        default:
            return {harp: HarpKeyC, harpNotes: HarpKeyCNotes}
  }
}

var A3 = "A3", B3 = "B3", C3 = "C3", D3 = "D3", E3 = "E3", F3 = "F3", G3 = "G3"
var A4 = "A4", B4 = "B4", C4 = "C4", D4 = "D4", E4 = "E4", F4 = "F4", G4 = "G4"
var A5 = "A5", B5 = "B5", C5 = "C5", D5 = "D5", E5 = "E5", F5 = "F5", G5 = "G5"
var A6 = "A6", B6 = "B6", C6 = "C6", D6 = "D6", E6 = "E6", F6 = "F6", G6 = "G6"
var A7 = "A7", B7 = "B7", C7 = "C7", D7 = "D7", E7 = "E7", F7 = "F7", G7 = "G7"

//ordne den Löchern den Frequensstreifen zu
//die ersten 10 sind blastöne, die letzt 10 sind zugtöne
var HarpKeyC = [
    12, 16, 18, 25, 30, 36, 49, 61, 73, 97,
    14, 18, 23, 27, 33, 41, 46, 55, 65, 82
]

/*
von C auf D und umgekehrt müsste möglichsein sind ja nur 2 Halbtöne
Alle    A2  A#2 B2  C3  C#3  D3 D3#  E3  F3 F3#  G3 G#3 A3  A#3 B3  C4  C#4  D4 D4#  E4  F4 F#4  G4 G#4 A4  A#4 B4  C5  C#5  D5 D5#  E5  F5 F#5  G5 G#5 A5  A#5 B5  C6  C#6  D6 D6#  E6  F6 F6#  G6 G#6 A6  A#6 B6  C7  C#7  D7 D7#  E7  F7 F7#  G7 G#7
TinC                                                                C4       D4      E4  F4      G4     A4      B4  C5       D5      E5  F5      G5     A5      B5
TinD                                                                         D4      E4     F#4  G4     A4      B4  C5  C#5  D5      E5     F#5  G5     A5      B5      C#6
HarpC               C3       D3      E3          G3             B3  C4       D4      E4  F4      G4     A4      B4  C5       D5      E5  F5      G5     A5          C6
G Key   G   A   B   C        D       E      F#   G      A       B   C        D       E      F#   G      A       B   C        D       E      F#   G      A       B   C        D       E      F#
*/

function checkAllsongs() {
    Song.map(song => {
        var OriginalNotes = extractNotesFromSong(song)
        var f = []
        
        function push(instrument) {
            if (f.indexOf(instrument) === -1) f.push(instrument)
        }
        function check(instrument, goalKey) {
            if (isSongForTune(OriginalNotes, instrument)) {
                push(instrument)
                return
            }
            if ((goalKey === "C" && song.key === "D") || (goalKey === "D" && song.key === "C")) {
                var temp = {
                    string: transposeSong(song.string, song.key)
                }
                if (isSongForTune(extractNotesFromSong(temp), instrument)) f.push(instrument)
            }
        }

        check("TinC", "C")
        check("TinD", "D")
        check("HarpC", "C")
        check("SaxoC", "C")
        console.log(typeof song.name === "object" ? song.name.de : song.name, "can:", f)
    })
}
window.checkAllsongs = checkAllsongs

export function transposeSong(notes, originalKey) {
    const trans = {
        CtoD: [
            ["c", "D0"], ["d", "E0"], ["e", "F#0"], ["f", "G0"], ["g", "A0"], ["a", "B0"], ["b", "C#+"]
        ],
        DtoC: [
            ["d","C0"], ["e","D0"], ["f#","E0"], ["g","F0"], ["a","G0"], ["b","A0"], ["c#", "B-"], ["c", "a#-"]
        ]
    }
    var table = null
    if (originalKey === "C") table = trans.CtoD
    if (originalKey === "D") table = trans.DtoC

    var replaceAt = function(index, replacement) {
        notes = notes.substr(0, index) + replacement + notes.substr(index + replacement.length);
    }
    //console.log(originalKey)
    table.map(data => {
        var oldNote = data[0]
        var newNote = data[1].substr(0, data[1].length-1)
        var pitchOffset = 0
        var lengthOffset = Math.max(0, (data[1].length -1) - data[0].length)
        if (data[1][data[1].length - 1] === "-") pitchOffset = -1
        if (data[1][data[1].length - 1] === "+") pitchOffset = 1

        var looper = 0
        while (notes.indexOf(oldNote) !== -1 && looper < 100) {
            looper++
            var pos = notes.indexOf(oldNote)
            notes = notes.replace(oldNote, newNote)
            var length = data[0].length

            replaceAt(pos + lengthOffset + 1, "" + (parseInt(notes[pos + lengthOffset + 1]) + pitchOffset))
        }
    })
    return notes
}

export function isSongForTune(notesArray, instrumentName) {
    var instrument = INSTRUMENTS[instrumentName]
    var result = notesArray.reduce((prev, cur) => {
        //console.log(prev, cur, instrument.indexOf(cur))
        if (prev === false) return false
        if (cur === "PA") return true

        if (instrument.indexOf(cur.toUpperCase()) === -1) console.log(instrumentName, "has no", cur)

        return instrument.indexOf(cur.toUpperCase()) !== -1
    }, true)
    
    return result
}

export function extractNotesFromSong(song) {
    return song.string.replace(/\|/g,";").replace(/\./g,"").replace(/\+/g, "").replace(/\/\d+/g, "").split(";")
}

export const INSTRUMENTS = {
    "TinC": ["C4", "D4", "E4", "F4", "G4", "A4", "B4", "C5", "D5", "E5", "F5", "G5", "A5", "B5"],
    "TinD": ["D4", "E4", "F#4", "G4", "A4", "B4", "C5", "C#5", "D5", "E5", "F#5", "G5", "A5", "B5"],
    "HarpC": ["C3", "D3", "E3", "G3", "B3", "C4", "D4", "E4", "F4", "G4", "A4", "B4", "C5", "D5", "E5", "F5", "G5", "A5", "C6"],
    "SaxoC": ["C4", "C#4", "D4", "D#4", "E4", "F4", "F#4", "G4", "G#4", "A4", "A#4", "B4", "C5", "C#5", "D5"]
}

export const TIN_TABS = {
    "TinC": ["111111", "111110", "111100", "111000", "110000", "100000", "000000", "011111p", "111110p", "111100p", "111000p", "110000p", "100000p", "000000p"],
    "TinD": ["111111", "111110", "111100", "111000", "110000", "100000", "011000", "000000", "011111p", "111110p", "111100p", "111000p", "110000p", "100000p"]
}

const SAXO_TABS = {
    "SaxoC": ["11111122", "11111121", "11111120", "11111110", "11111100", "11111000", "11110122", "11110000", "11101110", "11100000", "11011020", "11000000", "10100000", "01100000", "00100000"]
}

export var HarpKeyCNotes = [
    C3, E3, G3, C4, E4,   G4, C5, E5, G5, C6,
    D3, G3, B3, D4, F4,   A4, B4, D5, F5, A5
]