import Vue from 'vue';
import App from './App'
import Song from './songs'

/*Vue.component('headmenu', {
  props: [],
  template: `
  <div class="header">
    <div class="title">
      <h1 class="titlename">BlowTabs.com</h1><br>
    </div>
    <div class="dropdown">
      <button class="dropbtn"><i class="fas fa-globe-americas"></i> {{getLang()}}</button>
      <div class="dropdown-content">
        <a v-on:click="changeLang('en')">English (UK)</a>
        <a v-on:click="changeLang('de')">Deutsch (DE)</a>
      </div>
    </div>
    <div class="menu-tabs-container">
      <div class="menu-tab tab-active" v-on:click="swap($event, 'play')">{{this.$root.L("play")}}</div>
      <div class="menu-tab" v-on:click="swap($event, 'songs')">{{this.$root.L("chooseSong")}}</div>
      <div class="menu-tab" v-on:click="swap($event, 'harp')">{{this.$root.L("harp")}}</div>
      <div class="menu-tab" v-on:click="swap($event, 'tin')">{{this.$root.L("tinWhistle")}}</div>
      <div class="menu-tab" v-on:click="swap($event, 'free')">{{this.$root.L("allfree")}}</div>
    </div>
  </div>
        `,

  data: function () {
    return {
      sortKey: '',
      sortOrders: ''
    }
  },

  methods: {
    swap: function(button, key) {
      onResetBtn()
      app.$emit('silentium')
      onHeadMenu(button.target, key)
    },

    getLang: function() {
      return this.$root.selectedLang === "de" ? "Deutsch (DE)" : "English (UK)"
    },

    changeLang(lang) {
      app.selectedLang = lang
      Save.setSelectedLanguage(lang)
    }
  },

  computed: {
    
  }
})*/

/*Vue.component('songlist', {
  props: ["songdata"],
  template: `
  
  <div class="songlist-container">
  <div class="spacer"></div>
  <h3>{{this.$root.L("chooseSong")}}</h3>
  <div class="search-bar">
    <input name="query" v-model="searchQuery" placeholder="search" class="input searchbar">
  </div>
    <songentry v-for="n in currentIntrument" :songname="n.name" :songId="n.id" :strokeData="n.stroke"></songentry>
  </div>
        `,

  data: function () {
    return {
      sortKey: '',
      sortOrders: '',
      searchQuery: ''
    }
  },

  methods: {
  },

  computed: {
    currentIntrument: function () {
      var list = this.songdata.filter(s => s.instrument.indexOf(this.$root.currentInstrument) !== -1)
      var searchFilter = list.filter(s => {
        var name = getSongName(s.name, this.$root).toUpperCase()
        return name.indexOf(this.searchQuery.toUpperCase()) !== -1
      })
      var sortedList = searchFilter.sort((a, b)=> {
        var aName = getSongName(a.name, this.$root)
        var bName = getSongName(b.name, this.$root)
        return aName < bName ? -1 : 1
      })
      return sortedList
    }
  }
})

Vue.component('songfavoslist', {
  props: ["songdata"],
  template: `
  <div class="songfavolist-container">
    <h3>{{this.$root.L("youfavorites")}}</h3>
    <songentry v-for="n in favoList" :songname="n.name" :songId="n.id" :strokeData="n.stroke"></songentry>
  </div>
        `,

  data: function () {
    return {
      sortKey: '',
      sortOrders: ''
    }
  },

  methods: {
  },

  computed: {
    favoList: function () {
      return Song.filter(song => {
        return this.songdata.indexOf(song.id) != -1
      })
      //return this.songdata.filter(s => s.instrument.indexOf(this.$root.currentInstrument) !== -1)
    }
  }
})

Vue.component('songentry', {
  props: ["songname", "strokeData", "songId"],
  data: function () {
    return {
      plays: false
    }
  },
  template: `
  <div class="songentry">
    <button class="button songentry__play-button" v-on:click="playSong(songId)"><i v-show="plays === false" class="fa fa-play" aria-hidden="true"></i><i v-show="plays === true" class="fa fa-stop" aria-hidden="true"></i></button>
    <button class="button songentry__play-button" v-on:click="learn(songId)">{{this.$root.L("learn")}}</button>
    <!--<strokeIcon :count="getStrokeCount()" :length="getStrokeLength()"></strokeIcon>-->
    <div class="songlist-name">{{getSongName()}}</div>
    <img v-show="!isFavo(songId)" class="favo-star" src="img/favo_no.png" v-on:click="setFavo(songId, true)" alt="add"/>
    <img v-show="isFavo(songId)" class="favo-star" src="img/favo_yes.png" v-on:click="setFavo(songId, false)" alt="add"/>
  </div>
        `,
  created: function() {
    this.$root.$on('silentium', this.silentium)
  },
  methods: {
    learn: function(t) {
        app.$emit('silentium')
        config.id = t
        onHeadMenu( document.getElementsByClassName("menu-tab")[0], 'play')
    },
    getStrokeCount: function() {
      return this.strokeData.split("/")[0]
    },
    getStrokeLength: function() {
      return this.strokeData.split("/")[1]
    },
    isFavo: function(songId) {
      return this.$root.favosongs.indexOf(songId) != -1
    },
    getSongName() {
      if (typeof this.songname === "string") {
        return this.songname 
      } else {
        return this.songname[this.$root.selectedLang]
      }
    },
    setFavo: function(songId, addToFavo) {
      if (addToFavo && this.$root.favosongs.indexOf(songId) === -1) {
        this.$root.favosongs.push(songId)
      } else {
        this.$root.favosongs = this.$root.favosongs.filter(id => id !== songId)
      }
      Save.setFavoriteIds(this.$root.favosongs)
    },
    silentium: function() {
      if (this.plays) {
        this.playSong()
      }
    },
    playSong: function(id) {
      if (this.plays) {
        console.log("STOP SONG", id, this.plays)
        onResetBtn()
      } else {
        app.$emit('silentium')
        prelistenConfig.id = id
        applyConfig(prelistenConfig)
        setTimeout(function() {playSong(true)}, 100)
      }
      this.plays = !this.plays      
    }
  }
})

Vue.component('strokeIcon', {
  props: ["count", "length"],
  template: `
  <div class="stroke-container">
      <div class="stroke-count">{{count}}</div>
      <hr class="stroke-divider">
      <div class="stroke-length">{{length}}</div>
  </div>
        `
})

Vue.component('intrumenthub', {
  props: [],
  template: `
  <div class="instrumenthub">
    <div v-bind:class="{ 'instrumenthub__button-active' : this.$root.currentInstrument == 'HarpC', 'instrumenthub__button' : this.$root.currentInstrument != 'HarpC' }" alt="Harp C" v-on:click="setIntrument('HarpC', $event.target)"><img class="instrumenthub__icon" src="img/icon_HarpC.png"/></div>
    <div v-bind:class="{ 'instrumenthub__button-active' : this.$root.currentInstrument == 'TinC', 'instrumenthub__button' : this.$root.currentInstrument != 'TinC' }" alt="Tin Whislte C" v-on:click="setIntrument('TinC', $event.target)"><img class="instrumenthub__icon" src="img/icon_TinC.png"/></div>
    <div v-bind:class="{ 'instrumenthub__button-active' : this.$root.currentInstrument == 'TinD', 'instrumenthub__button' : this.$root.currentInstrument != 'TinD' }" alt="Tin Whislte D" v-on:click="setIntrument('TinD', $event.target)"><img class="instrumenthub__icon" src="img/icon_TinD.png"/></div>

    <div v-bind:class="{ 'instrumenthub__button-active' : this.$root.currentInstrument == 'SaxoC', 'instrumenthub__button' : this.$root.currentInstrument != 'SaxoC' }" alt="Saxonette C" v-on:click="setIntrument('SaxoC', $event.target)"><img class="instrumenthub__icon" src="img/icon_TinD.png"/></div>

    <div style="clear:both"></div>
    </div>
        `,
  
  methods: {
    setIntrument: function(name, currentButton) {
      this.$root.currentInstrument = name
      Save.setInstrument(name)
    }
  },
})

Vue.component('learntinwhistle', {
  props: [],
  template: `
  <div class="learn-tin">
    <div v-show="getLang() == 'en'">
      <h1>Learn to play the Tin Whislte</h1>
      <p class="learn-text">
      You want to learn the Tin Whislte (also called penny whistle)? A good choice, because a tin whistle is cheap and easy to play. Almost all songs on blowtabs.com can be played with a tin whistle in the key of C or D.<br>
      I will teach you everything you need to know here!
      </p>
      <h2>Which tin whistle?</h2>
      <p class="learn-text">
      Normally, when learning an instrument, you use the key C(-major), as most beginner songs are written in C.<br>
      But folk songs written for the Tin Whistle are usually in the key of D or G. The special thing about the tin whistle in D is that you can also play songs written in G.<br>
      Maybe you are not interested in Irish folk songs or you want to play songs that everyone knows, then you can also get a tin whistle in C.
      </p>
      <p class="learn-text">
      If a song is actually in C, but you have a tin whistle in D, that shouldn't be a problem, because I have almost all songs in both keys here :)
      </p>
      <h2>How to play</h2>
      <p class="learn-text">
      The tin whistle has two ends. You have to blow into one of them. If there is no sound, it was the wrong end. Place the index, middle and ring finger of your left hand on the three upper holes. In this case, the top is where your mouth is. The index, middle and ring fingers of your right hand cover the lower three holes. The thumbs support the flute from below and the little finger of the right hand supports the flute from above. I'll show you that again in the following video for safety.
      </p>
      <youtubevideo embed="2Nqv9oywExg"></youtubevideo>
      <p class="learn-text">
      You can swap hands too. The flute doesn't care. But try to stick to one grip if possible.
      </p>
      <p class="learn-text">
      If you now blow carefully into the tin whistle and all holes are well covered by your fingers, you will hear the deepest note. Now raise the lowest ring finger and blow again. You now play the next higher note on the scale. And so it goes on until all fingers are lifted. Pretty simple, isn't it? There are very few notes that deviate from this pattern.<br>
      If you want to play the high notes, you simply have to blow stronger into your tin whistle. This is called overblowing and allows you to play an octave higher. You can blow even stronger and play even higher. But this is a bit more absurd and you will hardly find it on this page. In the following video I played those higher notes and yes, this can be unpleasant.
      </p>
      <youtubevideo embed="xwqj4tpIyuo"></youtubevideo>
      <p class="learn-text">
      A little trick that will make it easier for you to play the notes is to hold back the air with your tongue. You don't have to hold your breath with your diaphragm between the notes, but clog the flow of air behind your teeth with your tongue. A bit like saying deh-deh-deh-deh but without using your voice. The video should help you!
      </p>
      <youtubevideo embed="SIgrCqaxj-0"></youtubevideo>
      <p class="learn-text">
      You can use your tongue to create various effects. Just play around a little bit. There has to be a bit of silliness.
      </p>
      <h2>The tab system, playing without notes</h2>
      <p class="learn-text">
      Reading notes can be quite exhausting and confusing. Because notes apply equally to all instruments. And because many people don't feel like learning notes there are tabs for many instruments. In the case of the tin whistle, these are small pictures that tell you which holes to cover and how hard to blow. White circles mean that the holes are open and black circles mean that you cover them with your fingers. If you see a small plus sign below it means that you have to go into the second octave. Here are the notes for the key of C and D, represented as real music notes and below as tabs.
      </p>
      <img class="learnimg" src="img/tonesc.png"/>
      <img class="learnimg" src="img/tonesd.png"/>
      <h2>That's how you learn the songs</h2>
      <p class="learn-text">
      Now you know everything to get started! Choose from the many songs one that seems easy to you and try to play it a few times tone by tone without paying attention to the length of the notes. Then set the speed of the song to 50% or slower, turn off the sound and play with the song until you have hit all the notes. Increase the speed by 5% and repeat the whole thing until you have reached 100%. From this point on, you may not know the song by heart but at the right speed. You will learn it by heart by trying not to look at the tabs and getting as far as possible.
      </p>
      <p class="learn-text">
      Have fun!
      </p>
      <p class="learn-text">
      PS: Everything on this page is completely free and without advertising, so I would be happy if you support the project and me and donate me via <a href="http://paypal.me/letsgamedev/3" target="_blank">Paypal</a>, thank you!
      </p>
      <p class="learn-text">
      PPS: You can twitter problems, wishes and improvement suggestions with the hashtag <a target="_blank" href="https://twitter.com/search/?q=%23blowtabs&f=realtime">#blowtabs</a>, that's the best way for me to see them :)
      </p>
    </div>

    <div v-show="getLang() == 'de'">
      <h1>Die Irische Blechflöte lernen</h1>
      <p class="learn-text">
      <a v-on:click="showVideo('tinBigVideoDe')">Keine Lust auf lesen? Klappe hier das ganze als Video aus <i class="fas fa-level-down-alt"></i></a> 
      <div id="tinBigVideoDe">
        <youtubevideo embed="eOAlG7kjY8w"></youtubevideo>
        <p class="learn-text"><a target="_blank" href="https://amzn.to/2FxyCVF">Blechflöte in C</a></p>
        <p class="learn-text"><a target="_blank" href="https://amzn.to/2JOLvNZ">Blechflöte in D</a></p>
      </div>
      </p>
      <p class="learn-text">
        Du hast Lust die Irische Blechflöte zu lernen? Eine gute Wahl, denn eine Blechflöte ist günstig und leicht zu spielen. Fast alle Lieder auf blowtabs.com kannst du mit einer Blechflöte in der Tonart C oder D spielen.<br>
        Ich werde dir hier alles, was du wissen musst beibringen!
      </p>
      <h2>Welche Blechflöte?</h2>
      <p class="learn-text">
        Normalerweise, wenn man ein Instrument lernt, greift man zur Tonart C(-Dur), da die meisten Anfängerlieder in C geschrieben sind. <br>
        Nun ist es aber so, dass Volkslieder, die für die Irische Blechflöte geschrieben wurden eher in D oder in G sind. Das besondere an der Blechflöte in D ist, dass du auch Lieder spielen kannst die in G geschrieben sind. <br>
        Vielleicht interessieren dich Irische Volkslieder aber auch gar nicht so sehr oder du möchtest eher Lieder spielen, die auch bei uns sehr bekannt sind, dann kannst du dir auch eine Blechflöte in C besorgen. 
      </p>
      <p class="learn-text">
        Wenn ein Lied eigentlich in C ist, du aber eine Blechflöte in D hast, sollte das aber gar kein Problem sein, denn ich habe so gut wie alle Lieder in beiden Tonarten hier :)
      </p>
      <p class="learn-text">
      Ich persönlich empfehle dir <a target="_blank" href="https://amzn.to/2JOLvNZ">diese Blechflöte in D</a> oder <a target="_blank" href="https://amzn.to/2FxyCVF">diese Blechflöte in C</a>. Keine von ihnen sollte dich mehr als 20€ kosten. Es gibt Flöten auch für bereits 5€ aber die machen oft Probleme in den hohen Tönen.
      <p class="learn-text">
      <a target="_blank" href="https://amzn.to/2JOLvNZ">Die Blechflöte in D</a>, die ich dir vorschlage, lässt sich sehr leicht spielen, du benötigst also nur sehr wenig Luft. Das ist praktisch um auch die hohen Töne leicht anspielen zu können, allerdings muss man bei den tiefen Tönen darauf achten nur sehr wenig Luft zu nutzen. Dennoch ist dies meine Lieblingsblechflöte :D
      </p>
      <p class="learn-text">
      <a target="_blank" href="https://amzn.to/2FxyCVF">Die Blechflöte in C</a> benötigt mehr Luft und verzeiht so in den tieferen Tönen mehr. Du kannst da also etwas wilder drauflospusten. Dafür benötigst du sehr viel mehr Atem und Druck um in die hohen Töne zu kommen.
      </p>
      <p class="learn-text">
      Das klingt schlimmer als es ist, glaub mir. Im vergleich mit anderen Instrumenten ist das wirklich nicht der Rede wert. So oder so wirst du den Dreh schnell raushaben.
      </p>
      <h2>Wie man spielt</h2>
      <p class="learn-text">
      Die Blechflöte hat zwei Enden. In eines musst du reinpusten. Kommt kein Ton, war es das falsche Ende. Den Zeige-, Mittel- und Ringfinger deiner Linken hand legst du auf die drei oberen Löcher. Oben ist in dem Fall dort, wo dein Mund ist. Der Zeige-, Mittel- und Ringfinger der rechten Hand verdecken die unteren drei Löcher. Die Daumen stützen die Flöte von unten und der kleine Finger der rechten Hand stützt die Flöte von Oben. Das zeige ich dir hier im folgenden Video zur Sicherheit noch einmal.
      </p>
      <youtubevideo embed="2Nqv9oywExg"></youtubevideo>
      <p class="learn-text">
      Du kannst die Hände auch tauschen. Der Flöte ist das egal und mir erst recht. Aber versuche möglichst bei einer Griffweise zu bleiben.
      </p>
      <p class="learn-text">
      Pustest du nun vorsichtig in die Blechflöte und alle Löcher sind von deinen Fingern gut abgedeckt, erklingt der tiefste Ton. Nun nimm den untersten Ringfinger hoch und puste wieder. Du spielst nun den nächsthöheren Ton auf der Tonleiter. Und so geht das immer weiter bis alle Finger angehoben sind. Ziemlich simpel oder? Es gibt nur sehr wenige Töne, die von diesem Schema abweichen.<br>
      Wenn du in die hohen Töne willst, musst du einfach stärker in deine Blechflöte pusten. Das wird Overblowing genannt und ermöglicht dir so eine Oktave höher zu spielen. Du kannst sogar noch stärker Pusten und so noch höher spielen. Das ist aber schon etwas unsinniger und wirst du hier auf der Seite kaum vorfinden. Im folgendem Video habe ich dir das mal vorgespielt und ja, die hohen Töne können unangenehm werden.
      </p>
      <youtubevideo embed="xwqj4tpIyuo"></youtubevideo>
      <p class="learn-text">
      Ein kleiner Trick, der dir das Anspielen der Noten erleichtern wird, ist das zurückhalten der Luft mit der Zunge. Dabei musst du zwischen den Noten den Atem nicht mit deinem Zwerchfell halten sondern verstopfst den Atemfluss hinter den Zähnen mit deiner Zunge. Ein bisschen so als würdest du deh-deh-deh-deh-deh sagen wollen aber ohne dein Stimmbänder zu nutzen. Das Video sollte dir dabei helfen!
      </p>
      <youtubevideo embed="SIgrCqaxj-0"></youtubevideo>
      <p class="learn-text">
      Du kannst mit der Zunge verschiedene Effekte hinbekommen. Probiere dich einfach ein bisschen aus. Ein bisschen Blödsinn muss auch mal sein.
      </p>
      <h2>Das Tabsystem, Spielen ohne Noten</h2>
      <p class="learn-text">
      Notenlesen kann ganz schön anstrengend und verwirrend sein. Denn Noten gelten für alle Instrumente gleichermaßen. Und weil viele Leute keine Lust haben zusätzlich noch Noten zu lernen gibt es für viele Instrumente sogenannte Tabs. Das sind im Fall der Blechflöte kleine Bilder, die dir sagen welche Löcher du zuhalten sollst und wie doll du pusten musst. Die Tabs habe ich im oberen Video bereits eingeblendet. Weiße Kreise bedeuten, dass die Löcher offen sind und Schwarze, dass du sie mit den Fingern verdeckst. Wenn du unterhalb ein kleines Plus siehst, heißt das, dass du in die zweite Oktave gehen musst. Hier noch die Noten für C und D, dargestellt als echte Musiknoten und darunter als Tabs.
      </p>
      <img class="learnimg" src="img/tonesc.png"/>
      <img class="learnimg" src="img/tonesd.png"/>
      <h2>So lernst du die Lieder</h2>
      <p class="learn-text">
      Jetzt weißt du alles, um anfangen zu können! Suche dir aus den vielen Liedern eines aus, das dir einfach erscheint und versuche es ein paar mal Ton für Ton nachzuspielen ohne auf die Länge der Noten zu achten. Danach stelle die Geschwindigkeit des Liedes auf 50% oder noch langsamer, schalte den Ton aus und spiele so lange mit dem Lied mit, bis du alle Töne getroffen hast. Erhöhe die Geschwindigkeit um 5% und wiederhole das ganze bist du bei 100% angelangt bist. Ab diesem Zeitpunkt, kannst du dann das Lied vielleicht noch nicht auswendig aber in der richtigen Geschwindigkeit. Auswendig lernst du es nun indem du versuchst nicht auf die Tabs zu sehen und so weit kommst wie möglich.
      </p>
      <p class="learn-text">
      Viel Spaß!
      </p>
      <p class="learn-text">
      PS.: Alles auf dieser Seite ist vollkommen kostenlos und ohne Werbung, daher würde ich mich freuen, wenn du das Projekt und mich untersützt und mir per <a href="http://paypal.me/letsgamedev/3" target="_blank">Paypal</a> den ein oder anderen Euro spendest, Danke dir!
      </p>
      <p class="learn-text">
      PPS.: Probleme, Wünsche und Verbesserungsvorschläge kannst du mit dem Hashtag <a target="_blank" href="https://twitter.com/search/?q=%23blowtabs&f=realtime">#blowtabs</a> twittern, so sehe ich sie am besten :)
      </p>
    </div>
  </div>
        `,
  methods: {
    showVideo: function() {
      var video = document.getElementById("tinBigVideoDe")
      console.log(video.style.height)
      if (video.style.height !== "515px") {
        video.style.height = "515px"
      } else {
        video.style.height = "0px"
      }
    },

    getLang: function() {
      return this.$root.selectedLang
    },
  }
})

Vue.component('learnharp', {
  props: [],
  template: `
  <div  class="learn-harp">
    <div v-show="getLang() == 'en'">
      <h1>Learn to play the harmonica</h1>
      <p class="learn-text">
        To be able to play songs on the harmonica is not that difficult! Here I give you everything you need to know to play any song listed on blowtabs.com!
      </p>
      <h2>Which harmonica?</h2>
      <p class="learn-text">
      The songs here are for the blues harp (diatonic harmonica). There are two types of harmonica available: Chromatic harmonicas have a small slider on the side and you can play notes in any key on them. Diatonic harmonicas do not have a slider and only have notes of a certain key, C for example. This is also the most common harmonica and therefore the songs here are tuned to C as well. If you have a harmonica in a different key, it doesn't matter, because the holes you have to play are always the same, the whole song just sounds a bit higher or lower.
      </p>
      <p class="learn-text">
      Some say the most expensive harmonica still costs less than the cheapest saxophone. I have had many harmonica for less than 10 bucks. Please don't do that. There may be some that still work well for little money, I just haven't experienced it yet. When I bought one between $20 and $30 I was stunned because it played so much better than the many cheap ones.
      </p>
      <h2How to play</h2>
      <p class="learn-text">
      Hold the harmonica as shown in the following video. It doesn't matter if you switch hands or not. It's all about giving the harmonica's sound more volume. As long as you hit the right holes, you can even hold it like a corncob. If the notes on the left sound lower than those on the right, you hold the harmonica the right way round. You can get two different notes from each hole. One when blowing in and one when drawing in the air.
      </p>
      <youtubevideo embed="ZK2L4ap4ZOA"></youtubevideo>
      <p class="learn-text">
      When you play it for the first time, you will probably play several notes at the same time. And that will always sound good! Because the notes are arranged so that you always play a chord (Richter tuning). But we want to play individual notes here. There are different possibilities for this:
      </p>
      <p class="learn-text">
      <b>The U-Blocking</b> is particularly suitable for beginners, because it does not fatigue the musculature of the mouth so fast. To do this, form a small U with your tongue and blow or draw on only one hole (which is actually called a cell). Don't be afraid to use your tongue to find the individual openings. After all, you play blind. Where which cell is, you learn bit by bit automatically. The disadvantage of U-blocking: The so-called bending is very difficult, but luckily we don't need it on blowtabs.com. This is what U-Blocking looks like:
      </p>
      <youtubevideo embed="eWNzEwZFcQE"></youtubevideo>
      <p class="learn-text">
      <b>The pucking</b> don't involves the tongue. You form a small hole with your lips similar to whistleling. The goal will be to master pucking (also called lipping), because with your tongue you can create effects. For example, you can make a tone sound deeper than it is supposed to. That's the bending.
      </p>
      <youtubevideo embed="6MKZeq490zs"></youtubevideo>
      <p class="learn-text">
      A little trick to play the notes better I call deh-deh-deh. You block the air with your tongue and release it again, as if you want to say "deh" but without using your voice. This makes it easier to distinguish individual notes if you have to play the same note several times in a row.
      </p>
      <youtubevideo embed="8xaCzDiTlCQ"></youtubevideo>
      <h2>The tab system, playing without notes</h2>
      <p class="learn-text">
      Reading notes can be quite exhausting and confusing. Because notes apply equally to all instruments. And because many people don't feel like learning notes there are tabs for many instruments. On the upper side of your harmonica, you will probably have a numbering of the comb from 1 to 10. And we use this numbering instead of notes. 4 means that you have to blow 4 in Kanzelle. And -6 means that you have to draw on the 6. The trick is to hit the right hole and not land too far to the left or right. You just have to practice that over and over again.
      </p>
      <h2>That's how you learn the songs</h2>
      <p class="learn-text">
      Now you know everything to get started! Choose from the many songs one that seems easy to you and try to play it a few times tone by tone without paying attention to the length of the notes. Then set the speed of the song to 50% or slower, turn off the sound and play with the song until you have hit all the notes. Increase the speed by 5% and repeat the whole thing until you have reached 100%. From this point on, you may not know the song by heart but at the right speed. You will learn it by heart by trying not to look at the tabs and getting as far as possible.
      </p>
      <p class="learn-text">
      Have fun!
      </p>
      <p class="learn-text">
      PS: Everything on this page is completely free and without advertising, so I would be happy if you support the project and me and donate me via <a href="http://paypal.me/letsgamedev/3" target="_blank">Paypal</a>, thank you!
      </p>
      <p class="learn-text">
      PPS: You can twitter problems, wishes and improvement suggestions with the hashtag <a target="_blank" href="https://twitter.com/search/?q=%23blowtabs&f=realtime">#blowtabs</a>, that's the best way for me to see them :)
      </p>
      </div>

      <div v-show="getLang() == 'de'">
      <h1>Mundharmonika lernen</h1>
      <p class="learn-text">
      <a v-on:click="showVideo('harpBigVideoDe')">Keine Lust auf lesen? Klappe hier das ganze als Video aus <i class="fas fa-level-down-alt"></i></a> 
      <div id="harpBigVideoDe">
        <youtubevideo embed="_jWI-01R_to"></youtubevideo>
        <p class="learn-text"><a target="_blank" href="https://amzn.to/2LkUSGd">Hier gibts eine gute Mundharmonika</a></p>
      </div>
      </p>
      <p class="learn-text">
        Lieder auf der Mundharmonika spielen zu können ist gar nicht so schwer! Hier gebe ich dir alles wichtige mit, was du brauchst um jedes Lied auf BlowTabs.com spielen zu können!
      </p>
      <h2>Welche Mundharmonika?</h2>
      <p class="learn-text">
        Die Lieder hier sind für die Bluesharp (Diatonische Mundharmonika) gedacht. Mundharmonikas kann man in zwei Varianten kaufen. Chromatische Mundharmonikas haben einen kleinen Schieber an der Seite und auf ihnen kann man Töne in jeder Tonart spielen. Diatonische Mundharmonikas haben keinen Schieber und besitzen grundsätzlich nur Töne einer bestimmten Tonart, C zum Beispiel. Das ist auch die häufigste Mundharmonika und darum sind die Lieder hier auch auf C gestimmt. Falls du eine Mundharmonika in einer anderen Tonart hast, ist das egal, denn die Löcher, die du anspielen musst sind immer die gleichen, das ganze Lied klingt dann nur etwas höher oder tiefer.
      </p>
      <p class="learn-text">
        Man sagt die teuerste Mundharmonika kostet immernoch weniger als das günstigste Saxophon. Ich habe in den letzten Jahren viele Mundharmonikas für unter 10€ gehabt. Mach das bitte nicht. Es mag einige geben, die für wenig Geld trotzdem gut funktionieren, ich habe es nur noch nicht erlebt. Als ich mir dann eine zwischen 20€ und 30€ kaufte war ich baff, weil sie sich so viel besser spielte als die vielen Billigen. Du kannst dir zum Beispiel diese hier holen: <a target="_blank" href="https://amzn.to/2LkUSGd">LINK</a>. Oder eine andere mit guten Bewertungen im gleichen Preissegment.
      </p>
      <h2>Wie man spielt</h2>
      <p class="learn-text">
        Halte die Mundharmonika wie im Folgenden Video gezeigt. Dabei ist es egal, ob du die Hände vertauscht oder nicht. Es geht nur darum, der Mundharmonika mehr Klangvolumen zu geben. Meinetwegen kannst du sie auch wie einen Maiskolben halten, solange du die richtigen Löcher triffst. Wenn die Töne auf der linken Seite tiefer klingen als auf der rechten, hältst du die Mundharmonika richtigherum. Du kannst aus jedem Loch zwei verschiedene Töne holen. Einen beim Reinpusten und einen beim Einziehen der Luft.
      </p>
      <youtubevideo embed="ZK2L4ap4ZOA"></youtubevideo>
      <p class="learn-text">
        Beim ersten Reinpusten wirst du wahrscheinlich mehrere Töne gleichzeitig spielen. Und das wird immer gut klingen! Denn die Töne sind so angeordnet (Richterstimmung) dass du immer einen Akkord spielst. Aber wir wollen hier ja gezielt einzelne Töne anspielen. Dafür gibt es verschiedene Möglichkeiten:
      </p>
      <p class="learn-text">
      <b>Das U-Blocking</b> ist besonders für Anfänger geeignet, da es die Mundmuskulatur nicht so schnell ermüden lässt. Dazu bildest du mit der Zunge ein kleines U und pustest oder ziehst so gezielt an nur einem Kanal (Kanzelle heißt das eigentlich). Scheue dich nicht mit der Zunge die einzellnen Öffnungen abzufahren um die richtige Kanzelle zu finden. Du spielst ja schließlich blind. Wo welches Loch ist, lernst du nach und nach automatisch. Der Nachteil des U-Blockings: Das sogenannte Bending ist sehr schwer, aber das brauchen wir zum Glück für kein einziges Lied auf BlowTabs.com. So sieht das U-Blocking aus:
      </p>
      <youtubevideo embed="eWNzEwZFcQE"></youtubevideo>
      <p class="learn-text">
      <b>Das Pucking</b> lässt die Zunge frei. Du bildest ähnlich wie beim Pfeiffen ein kleines Loch mit deinen Lippen, schiebst dir die Mundharmonika aber weiter in den Mund. Ziel wird es sein, irgendwann das Pucking (auch Lipping genannt) zu beherrschen, denn mit der Zunge kannst du so Effekte erzeugen. Zum Beispiel einen Ton tiefer klingen lassen als er eigentlich gedacht ist; das ist dann das Bending.
      </p>
      <youtubevideo embed="6MKZeq490zs"></youtubevideo>
      <p class="learn-text">
      Ein kleiner Trick um die Noten besser anspielen zu können nenne ich deh-deh-deh. Dabei blockst du die Luft mit der Zunge ung gibst sie wieder frei so als ob du "de" sagen willst aber ohne etwas zu sagen. So lassen sich einzelne Noten besser von einander unterscheiden, wenn mehrfach die gleiche hintereinander gespielt werden muss.
      </p>
      <youtubevideo embed="8xaCzDiTlCQ"></youtubevideo>
      <h2>Das Tabsystem, Spielen ohne Noten</h2>
      <p class="learn-text">
      Notenlesen kann ganz schön anstrengend und verwirrend sein. Denn Noten gelten für alle Instrumente gleichermaßen. Und weil viele Leute keine Lust haben zusätzlich noch Noten zu lernen gibt es für viele Instrumente sogenannte Tabs. Auf deiner Mundharmonika wirst du auf der Oberseite wahrscheinlich eine Durchnummerierung der Kanzellen haben, von 1 bis 10. Und diese Nummerierung nutzen wir anstelle von Noten. 4 bedeutet, dass du in Kanzelle 4 pusten sollst. Und -6 bedeutet, dass du an Kanzelle 6 ziehen musst. Die Kunst ist das zielsichere Treffen und nicht ein Loch zu weit links oder rechts zu landen. Das musst du einfach immer wieder üben.
      </p>
      <h2>So lernst du die Lieder</h2>
      <p class="learn-text">
      Jetzt weißt du alles, um anfangen zu können! Suche dir aus den vielen Liedern eines aus, das dir einfach erscheint und versuche es ein paar mal Ton für Ton nachzuspielen ohne auf die Länge der Noten zu achten. Danach stelle die Geschwindigkeit des Liedes auf 50% oder noch langsamer, schalte den Ton aus und spiele so lange mit dem Lied mit, bis du alle Töne getroffen hast. Erhöhe die Geschwindigkeit um 5% und wiederhole das ganze bist du bei 100% angelangt bist. Ab diesem Zeitpunkt, kannst du dann das Lied vielleicht noch nicht auswendig aber in der richtigen Geschwindigkeit. Auswendig lernst du es nun indem du versuchst nicht auf die Tabs zu sehen und so weit kommst wie möglich.
      </p>
      <p class="learn-text">
      Viel Spaß!
      </p>
      <p class="learn-text">
      PS.: Alles auf dieser Seite ist vollkommen kostenlos und ohne Werbung, daher würde ich mich freuen, wenn du das Projekt und mich untersützt und mir per <a href="http://paypal.me/letsgamedev/3" target="_blank">Paypal</a> den ein oder anderen Euro spendest, Danke dir!
      </p>
      <p class="learn-text">
      PPS.: Probleme, Wünsche und Verbesserungsvorschläge kannst du mit dem Hashtag <a target="_blank" href="https://twitter.com/search/?q=%23blowtabs&f=realtime">#blowtabs</a> twittern, so sehe ich sie am besten :)
      </p>
      </div>
  </div>
        `,
  methods: {
    showVideo: function() {
      var video = document.getElementById("harpBigVideoDe")
      console.log(video.style.height)
      if (video.style.height !== "465px") {
        video.style.height = "465px"
      } else {
        video.style.height = "0px"
      }
    },

    getLang: function() {
      return this.$root.selectedLang
    },
  }
})

Vue.component('youtubevideo', {
  props: ["embed"],
  template: `
  <iframe width="1920" class="video" height="1080" :src="getEmbedUrl()" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        `,
  methods: {
    getEmbedUrl: function() {
      return "https://www.youtube.com/embed/" + this.embed
    }
  }
})

Vue.component('free', {
  props: [],
  template: `
  <div class="free">
    <div v-show="getLang() == 'en'">
      <h1>Everything is free!</h1>
      <p class="learn-text">
      Everything on this page is completely free and without advertising, so I would be happy if you support the project and me and donate me a little bit by <a href="http://paypal.me/letsgamedev/3" target="_blank">Paypal</a>, thank you!
      <a href="http://paypal.me/letsgamedev/3" target="_blank"><img class="paypal-button" src="img/paypal.png"/></a>
      </p>
      <p class="learn-text">
      PS: You can twitter problems, wishes and improvement suggestions with the hashtag <a target="_blank" href="https://twitter.com/search/?q=%23blowtabs&f=realtime">#blowtabs</a>, that's the best way for me to see them :)
      </p>
    </div>

    <div v-show="getLang() == 'de'">
      <h1>Alles ist kostenlos!</h1>
      <p class="learn-text">
      Alles auf dieser Seite ist vollkommen kostenlos und ohne Werbung, daher würde ich mich freuen, wenn du das Projekt und mich untersützt und mir per <a href="http://paypal.me/letsgamedev/3" target="_blank">Paypal</a> den ein oder anderen Euro spendest oder mal über meinen Amazonlink irgendetwas kaufst, dann muss <a target="_blank" href="https://amzn.to/2LkUSGd">Amazon</a> mir ein bisschen was schenken und dich kostet es nicht mehr, Danke dir!
      <a href="http://paypal.me/letsgamedev/3" target="_blank"><img class="paypal-button" src="img/paypal.png"/></a>
      <a target="_blank" href="https://amzn.to/2LkUSGd"><img class="paypal-button" src="img/amazon.png"/></a>
      </p>
      <p class="learn-text">
      PS.: Probleme, Wünsche und Verbesserungsvorschläge kannst du mit dem Hashtag <a target="_blank" href="https://twitter.com/search/?q=%23blowtabs&f=realtime">#blowtabs</a> twittern, so sehe ich sie am besten :)
      </p>
    </div>
  </div>
        `,
  methods: {
    showVideo: function() {
      var video = document.getElementById("harpBigVideoDe")
      console.log(video.style.height)
      if (video.style.height !== "465px") {
        video.style.height = "465px"
      } else {
        video.style.height = "0px"
      }
    },

    getLang: function() {
      return this.$root.selectedLang
    }
  },
})

Vue.component('impressum', {
  props: [],
  template: `
  <div class="impressum">
    <div v-show="getLang() == 'en'">
      <h1>Impressum</h1>
      <p class="learn-text">
      <address>
      Tom Bleek<br>
      Firlstr. 27<br>
      12459 Berlin<br>
      GERMANY<br>
      <br>
      Kontakt: <br>
      E-Mail: letsgamedev@gmx.de<br>
      </address>
      </p>
      <h1>Data Protection & Privacy</h1>
      <p class="learn-text">
      I do not transmit data to third parties. Not even to me or anyone else. All data used for using blowtabs.com is only stored on your hard drive. With the delete button below, you can delete all saved data.
      </p>
      <a class="paypal-button" onclick="Save.deletEverything()">delete data</a>
    </div>
    <div v-show="getLang() == 'de'">
      <h1>Impressum</h1>
      <p class="learn-text">
      <address>
      Tom Bleek<br>
      Firlstr. 27<br>
      12459 Berlin<br>
      GERMANY<br>
      <br>
      Kontakt: <br>
      E-Mail: letsgamedev@gmx.de<br>
      </address>
      </p>
      <h1>Datenschutz</h1>
      <p class="learn-text">
      Ich übermittle keine Daten an dritte. Nicht mal an mich oder sonstwen. Alle Daten die für das Verwenden von blowtabs.com verwendet werden, sind nur auf deiner Festplatte gespeichert. Mit dem Löschen-Knopf unten, kannst du alle gespeicherten Daten löschen.
      </p>
      <a class="paypal-button" onclick="Save.deletEverything()">Daten löschen</a>
    </div>
  </div>
        `,
  methods: {
    getLang: function() {
      return this.$root.selectedLang
    }
  }
})

Vue.component('privacy', {
  props: [],
  template: `
    <div>  
      <div v-show="getLang() == 'en'">
        <footer><div id="footer"><a class="impressum" onclick="onHeadMenu(this, 'impressum')" style="color:white;">imprint & privacy</a></div></footer>
      </div>
      <div v-show="getLang() == 'de'">
        <footer><div id="footer"><a class="impressum" onclick="onHeadMenu(this, 'impressum')" style="color:white;">impressum & datenschutz</a></div></footer>
      </div>
    </div
        `,
  methods: {
    getLang: function() {
      return this.$root.selectedLang
    }
  }
})

Vue.component('song', {
  props: ["songdata", "songname"],
  template: `
  <div class="songContainer">
      <div class="songname">{{getSongName()}}</div>
      <audiobuttons></audiobuttons>
      <div class="tab-container">
        <stroke v-for="n in songdata" :songdata="n"></stroke>
      </div>
      <div style="clear:both"></div>
      <div class="spacer"></div>
  </div>
        `,
  methods: {
    getSongName() {
      if (typeof this.songname === "string") {
        return this.songname 
      } else {
        return this.songname[this.$root.selectedLang]
      }
    }
  }
})

Vue.component('audiobuttons', {
  props: [],
  template: `
  <div class="audiobuttons">
      <button class="button" id="playBtn" v-on:click="play()"><i class="fa fa-play" aria-hidden="true"></i></button>
      <button class="button" id="resetBtn" onclick="onResetBtn()"><i class="fa fa-step-backward" aria-hidden="true"></i></button>
      <input type="range" min="20" max="150" value="100" step="5" class="slider is-fullwidth" oninput="updateSpeedSlider()" id="speedSlider"><span id='speedValue'>Speed: 100%</span>
      <label class="switch">
        <input type="checkbox"  oninput="onMusicSwitch()" checked="checked" id="soundCheckBox">
        <span class="switch-slider"></span>
      </label>
      <i class="fas fa-music" id="soundCheckIcon"></i>
      <label id="loopLabel" class="switch">
        <input type="checkbox" oninput="onLoopSwitch()" checked="checked" id="loopCheckBox">
        <span class="switch-slider"></span>
      </label>
      <i class="fas fa-undo-alt" id="loopCheckIcon"></i>
  </div>
        `,
  methods: {
    play: function() {
      config.speed = document.getElementById("speedSlider").value
      Save.setLastLearnedSongConfig(config)
      playSong()
    }
  },
})

Vue.component('tintabsbuttons', {
  props: [],
  template: `
  <div class="tintabsbuttons">
  <div>C Tabs</div>
      <img class="tintabsbutton" src="img/tin_tab_111111.png" v-on:click="addNode('c4')"/>
      <img class="tintabsbutton" src="img/tin_tab_111110.png" v-on:click="addNode('d4')"/>
      <img class="tintabsbutton" src="img/tin_tab_111100.png" v-on:click="addNode('e4')"/>
      <img class="tintabsbutton" src="img/tin_tab_111000.png" v-on:click="addNode('f4')"/>
      <img class="tintabsbutton" src="img/tin_tab_110000.png" v-on:click="addNode('g4')"/>
      <img class="tintabsbutton" src="img/tin_tab_100000.png" v-on:click="addNode('a4')"/>
      <img class="tintabsbutton" src="img/tin_tab_000000.png" v-on:click="addNode('b4')"/>
     
      <img class="tintabsbutton" src="img/tin_tab_011111p.png" v-on:click="addNode('c5')"/>
      <img class="tintabsbutton" src="img/tin_tab_111110p.png" v-on:click="addNode('d5')"/>
      <img class="tintabsbutton" src="img/tin_tab_111100p.png" v-on:click="addNode('e5')"/>
      <img class="tintabsbutton" src="img/tin_tab_111000p.png" v-on:click="addNode('f5')"/>
      <img class="tintabsbutton" src="img/tin_tab_110000p.png" v-on:click="addNode('g5')"/>
      <img class="tintabsbutton" src="img/tin_tab_100000p.png" v-on:click="addNode('a5')"/>
      <img class="tintabsbutton" src="img/tin_tab_000000p.png" v-on:click="addNode('b5')"/>
  
      
      
      <div>D Tabs</div>
      <img class="tintabsbutton" src="img/tin_tab_111111.png" v-on:click="addNode('d4')"/>
      <img class="tintabsbutton" src="img/tin_tab_111110.png" v-on:click="addNode('e4')"/>
      <img class="tintabsbutton" src="img/tin_tab_111100.png" v-on:click="addNode('f#4')"/>
      <img class="tintabsbutton" src="img/tin_tab_111000.png" v-on:click="addNode('g4')"/>
      <img class="tintabsbutton" src="img/tin_tab_110000.png" v-on:click="addNode('a4')"/>
      <img class="tintabsbutton" src="img/tin_tab_100000.png" v-on:click="addNode('b4')"/>
      <img class="tintabsbutton" src="img/tin_tab_011000.png" v-on:click="addNode('c5')"/>
      <img class="tintabsbutton" src="img/tin_tab_000000.png" v-on:click="addNode('c#5')"/>

      <img class="tintabsbutton" src="img/tin_tab_011111p.png" v-on:click="addNode('d5')"/>
      <img class="tintabsbutton" src="img/tin_tab_111110p.png" v-on:click="addNode('e5')"/>
      <img class="tintabsbutton" src="img/tin_tab_111100p.png" v-on:click="addNode('f#5')"/>
      <img class="tintabsbutton" src="img/tin_tab_111000p.png" v-on:click="addNode('g5')"/>
      <img class="tintabsbutton" src="img/tin_tab_110000p.png" v-on:click="addNode('a5')"/>
      <img class="tintabsbutton" src="img/tin_tab_100000p.png" v-on:click="addNode('b5')"/>
  </div>
        `,
  methods: {
    addNode: function(noteName) {
      document.getElementById("eNotes").value += noteName
    }
  },
})



Vue.component('stroke', {
  props: ["songdata"],
  template: `
  <div class="stroke">
      <note v-for="n in songdata" :channel=n.channel :text="n.text" :lengthInPixel="n.lengthInPixel"></note>
  </div>
        `
})

Vue.component('note', {
  props: ["channel", "text", "lengthInPixel"],
  template: `
          <div :class='"noteContainer"' :style='"width: "+lengthInPixel+"px;"'>
              <div class="note"><span class="song-bar"></span><span class="song-bar-back"></span><div v-html="channel"></div></div>
              <div class="songText">{{text}}</div>
          </div>
        `
})

Vue.component('editorfield', {
  props: ["name", "def", "id"],
  template: `
          <div class="field is-horizontal">
            <div class="field-label is-normal"><label class="label">{{name}}</label></div>
            <div class="field-body"><div class="field"><div class="control">
              <input :id="id" class="input" type="text" :placeholder="name" :value="def">
            </div></div></div>
          </div>
        `
})

Vue.component('editorfield2', {
  props: ["name", "def", "id"],
  template: `
          <div class="field is-horizontal">
            <div class="field-label is-normal"><label class="label">{{name}}</label></div>
            <div class="field-body"><div class="field"><div class="control">
              <textarea rows="4" cols="50" :id="id" class="input" type="text" :placeholder="name" :value="def"></textarea>
            </div></div></div>
          </div>
        `
})*/
new Vue(App).$mount('#app')
/*var app = new Vue({
  el: '#app',
  components: { App },
  template: '<App/>',
  mounted: function() {
    //setTimeout(initialiseSite, 0)
    //this.$root.selectedLang = Save.getSelectedLanguage() || this.$root.selectedLang
  },
  data: {
    lang: {
      de: {
        subtitle: 'Spielend lernen!',
        play: 'Spielen',
        chooseSong: "Alle Lieder",
        harpsongs: 'Mundharmonika',
        tinsongs: 'Blechflöte',
        allfree: 'Alles Kostenlos!',
        learn: "Lernen",
        tinWhistle: "Irische Blechflöte",
        harp: "Mundharmonika",
        youfavorites: "Deine Favoriten"
      },

      en: {
        subtitle: 'The the the english the they cool.',
        play: 'Play',
        chooseSong: 'All Songs',
        harpsongs: 'Harp Songs',
        tinsongs: 'Tin Whistle Songs',
        allfree: 'Everything is free!',
        learn: "Learn",
        tinWhistle: "Tin Whistle",
        harp: "Blues Harp",
        youfavorites: "Your favorites"
      }
    },

    songs: Song,
    song: [],
    currentInstrument: 'HarpC',
    favosongs: [],
    name: 'Wähle einen Song :)',
    searchQuery: '',
    selectedLang: navigator.language.indexOf("de") !== -1 ? "de" : "en"
  },
  
  
  methods: {
      L: function(key) {
        var parts = [this.selectedLang, ...key.split(".")]
        return parts.reduce((prev, curKey) => prev[curKey], this.lang)

      }
  }
})*/



function getSongName(songname, instance = app) {
  if (typeof songname === "string") {
    return songname 
  } else {
    return songname[instance.selectedLang]
  }
}