<template>
    <div>
        <headmenu></headmenu>
        <div class="content">
                <instrumenthub></instrumenthub>
                <song :songdata="song" :songname="name"></song>
                <songfavoslist :songdata=favosongs></songfavoslist>
                
                <songlist :songdata="songs"></songlist>
                <learnharp></learnharp>
                <learntinwhistle></learntinwhistle>
                <free></free>
                <div style="clear:both"></div>
                <div id="editor">
                    <button class="button" id="editorBtn" v-on:click="loadSong()"><i class="fa fa-play" aria-hidden="true"></i></button>
                    <button class="button" id="editorBtnConvert" v-on:click="convertTabsToNotes()"><i class="fa fa-play" aria-hidden="true"></i></button>
                    <editorfield2 name="Noten" def="" id="eNotes"></editorfield2>
                    <editorfield name="BPM" def="120" id="eBpm"></editorfield>
                    <editorfield name="Takt" def="4/4" id="eStroke"></editorfield>
                    <editorfield name="Key" def="C" id="eKey"></editorfield>
                    <tintabsbuttons></tintabsbuttons>
                    <p>Achte beim Text darauf, dass zu jeder Note ein Textfragment bereit steht. Als Trennzeichen dient das Leerzeichen. Falls eine Note keinen Text hat, so nutze das ? so erkennt Harpo das unter der Note nichts kommt.</p>
                </div>
                <impressum></impressum>
            </div>
        <privacy></privacy>
        
    </div>
</template>

<script>
import Vue from "vue";
import {initialiseSite} from './main'
import {Save} from './save'
import {Song} from './songs'
import headmenu from './components/headmenu'
import privacy from './components/privacy'
import instrumenthub from './components/instrumenthub'
import song from './components/song'
import impressum from './components/impressum'
import songfavoslist from './components/songfavoslist'
import songlist from './components/songlist'
import learnharp from './components/learnharp'
import learntinwhistle from './components/learntinwhistle'
import free from './components/free'
import editorfield from './components/editorfield'
import editorfield2 from './components/editorfield2'
import tintabsbuttons from './components/tintabsbuttons'
import {loadEditorSong, convertHarpTabsToNotes} from './main' 


export default Vue.extend({
  components: {
    headmenu,
    privacy,
    instrumenthub,
    song,
    impressum,
    songfavoslist,
    songlist,
    learnharp,
    learntinwhistle,
    free,
    editorfield,
    editorfield2,
    tintabsbuttons
  },
  data() {
    return {
      bundler: "Parcel",
      lang: {
          de: {
            subtitle: 'Spielend lernen!',
            play: 'Spielen',
            chooseSong: "Alle Lieder",
            harpsongs: 'Mundharmonika',
            tinsongs: 'Blechflöte',
            allfree: 'Alles Kostenlos!',
            learn: "Lernen",
            tinWhistle: "Irische Blechflöte",
            harp: "Mundharmonika",
            youfavorites: "Deine Favoriten"
        },
        en: {
            subtitle: 'The the the english the they cool.',
            play: 'Play',
            chooseSong: 'All Songs',
            harpsongs: 'Harp Songs',
            tinsongs: 'Tin Whistle Songs',
            allfree: 'Everything is free!',
            learn: "Learn",
            tinWhistle: "Tin Whistle",
            harp: "Blues Harp",
            youfavorites: "Your favorites"
        }
      },
      songs: Song,
      song: [],
      currentInstrument: 'HarpC',
      favosongs: [],
      name: 'Wähle einen Song :)',
      searchQuery: '',
      selectedLang: navigator.language.indexOf("de") !== -1 ? "de" : "en"
    };
  },
  mounted: function() {
    window.app = this.$root;
    setTimeout(initialiseSite, 0)
    this.$root.selectedLang = Save.getSelectedLanguage() || this.$root.selectedLang
  },
  methods: {
      L(key) {
        var parts = [this.selectedLang, ...key.split(".")]
        return parts.reduce((prev, curKey) => prev[curKey], this.lang)

      },
      getSongName(songname) {
        if (typeof songname === "string") {
            return songname 
        } else {
            return songname[this.selectedLang]
        }
      },

      loadSong() {
        loadEditorSong()
      },
      convertTabsToNotes() {
        convertHarpTabsToNotes()
      }
  }
});
</script>