<template>
<div class="songlist-container">
  <div class="spacer"></div>
  <h3>{{this.$root.L("chooseSong")}}</h3>
  <div class="search-bar">
    <input name="query" v-model="searchQuery" placeholder="search" class="input searchbar">
  </div>
    <songentry v-for="n in currentIntrument" :songname="n.name" :songId="n.id" :strokeData="n.stroke"></songentry>
</div>
</template>

<script>
import songentry from './songentry'

export default {
    name: 'songlist',
    components: {
        songentry
    },
    props: ["songdata"],
    data: function () {
    return {
      sortKey: '',
      sortOrders: '',
      searchQuery: ''
    }
  },
  computed: {
    currentIntrument: function () {
      var list = this.songdata.filter(s => s.instrument.indexOf(this.$root.currentInstrument) !== -1)
      var searchFilter = list.filter(s => {
        var name = this.$root.getSongName(s.name).toUpperCase()
        return name.indexOf(this.searchQuery.toUpperCase()) !== -1
      })
      var sortedList = searchFilter.sort((a, b)=> {
        var aName = this.$root.getSongName(a.name)
        var bName = this.$root.getSongName(b.name)
        return aName < bName ? -1 : 1
      })
      return sortedList
    }
  }
}
</script>