<template>
    <div class="instrumenthub">
    <div v-bind:class="{ 'instrumenthub__button-active' : this.$root.currentInstrument == 'HarpC', 'instrumenthub__button' : this.$root.currentInstrument != 'HarpC' }" alt="Harp C" v-on:click="setIntrument('HarpC', $event.target)"><img class="instrumenthub__icon" src="../../assets/img/icon_HarpC.png"/></div>
    <div v-bind:class="{ 'instrumenthub__button-active' : this.$root.currentInstrument == 'TinC', 'instrumenthub__button' : this.$root.currentInstrument != 'TinC' }" alt="Tin Whislte C" v-on:click="setIntrument('TinC', $event.target)"><img class="instrumenthub__icon" src="../../assets/img/icon_TinC.png"/></div>
    <div v-bind:class="{ 'instrumenthub__button-active' : this.$root.currentInstrument == 'TinD', 'instrumenthub__button' : this.$root.currentInstrument != 'TinD' }" alt="Tin Whislte D" v-on:click="setIntrument('TinD', $event.target)"><img class="instrumenthub__icon" src="../../assets/img/icon_TinD.png"/></div>
    <div style="clear:both"></div>
    </div>
</template>

<script>
import {Save} from '../save'

export default {
    name: 'instrumenthub',
    methods: {
    setIntrument: function(name, currentButton) {
      this.$root.currentInstrument = name
      Save.setInstrument(name)
    }
  }
}
</script>