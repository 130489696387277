<template>
    <div class="free">
    <div v-show="getLang() == 'en'">
      <h1>Everything is free!</h1>
      <p class="learn-text">
      Everything on this page is completely free and without advertising, so I would be happy if you support the project and me and donate me a little bit by <a href="http://paypal.me/letsgamedev/3" target="_blank">Paypal</a>, thank you!
      <a href="http://paypal.me/letsgamedev/3" target="_blank"><img class="paypal-button" src="../../assets/img/paypal.png"/></a>
      </p>
      <p class="learn-text">
      PS: You can twitter problems, wishes and improvement suggestions with the hashtag <a target="_blank" href="https://twitter.com/search/?q=%23blowtabs&f=realtime">#blowtabs</a>, that's the best way for me to see them :)
      </p>
    </div>

    <div v-show="getLang() == 'de'">
      <h1>Alles ist kostenlos!</h1>
      <p class="learn-text">
      Alles auf dieser Seite ist vollkommen kostenlos und ohne Werbung, daher würde ich mich freuen, wenn du das Projekt und mich untersützt und mir per <a href="http://paypal.me/letsgamedev/3" target="_blank">Paypal</a> den ein oder anderen Euro spendest oder mal über meinen Amazonlink irgendetwas kaufst, dann muss <a target="_blank" href="https://amzn.to/2LkUSGd">Amazon</a> mir ein bisschen was schenken und dich kostet es nicht mehr, Danke dir!
      <a href="http://paypal.me/letsgamedev/3" target="_blank"><img class="paypal-button" src="../../assets/img/paypal.png"/></a>
      <a target="_blank" href="https://amzn.to/2LkUSGd"><img class="paypal-button" src="../../assets/img/amazon.png"/></a>
      </p>
      <p class="learn-text">
      PS.: Probleme, Wünsche und Verbesserungsvorschläge kannst du mit dem Hashtag <a target="_blank" href="https://twitter.com/search/?q=%23blowtabs&f=realtime">#blowtabs</a> twittern, so sehe ich sie am besten :)
      </p>
    </div>
  </div>
</template>

<script>
export default {
    name: 'free',
    methods: {
    showVideo: function() {
      var video = document.getElementById("harpBigVideoDe")
      console.log(video.style.height)
      if (video.style.height !== "465px") {
        video.style.height = "465px"
      } else {
        video.style.height = "0px"
      }
    },

    getLang: function() {
      return this.$root.selectedLang
    }
  },
}
</script>