<template>
    <div class="impressum">
    <div v-show="getLang() == 'en'">
      <h1>Impressum</h1>
      <p class="learn-text">
      <address>
      Tom Bleek<br>
      Firlstr. 27<br>
      12459 Berlin<br>
      GERMANY<br>
      <br>
      Kontakt: <br>
      E-Mail: letsgamedev@gmx.de<br>
      </address>
      </p>
      <h1>Data Protection & Privacy</h1>
      <p class="learn-text">
      I do not transmit data to third parties. Not even to me or anyone else. All data used for using blowtabs.com is only stored on your hard drive. With the delete button below, you can delete all saved data.
      </p>
      <a class="paypal-button" onclick="Save.deletEverything()">delete data</a>
    </div>
    <div v-show="getLang() == 'de'">
      <h1>Impressum</h1>
      <p class="learn-text">
      <address>
      Tom Bleek<br>
      Firlstr. 27<br>
      12459 Berlin<br>
      GERMANY<br>
      <br>
      Kontakt: <br>
      E-Mail: letsgamedev@gmx.de<br>
      </address>
      </p>
      <h1>Datenschutz</h1>
      <p class="learn-text">
      Ich übermittle keine Daten an dritte. Nicht mal an mich oder sonstwen. Alle Daten die für das Verwenden von blowtabs.com verwendet werden, sind nur auf deiner Festplatte gespeichert. Mit dem Löschen-Knopf unten, kannst du alle gespeicherten Daten löschen.
      </p>
      <a class="paypal-button" v-on:click="deletEverything()">Daten löschen</a>
    </div>
  </div>
</template>

<script>
import {Save} from './../save'

export default {
    name: 'impressum',
    methods: {
    getLang: function() {
      return this.$root.selectedLang
    },
    deletEverything: Save.deletEverything
  }
}
</script>