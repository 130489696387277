<template>
      <iframe width="1920" class="video" height="1080" :src="getEmbedUrl()" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
</template>

<script>
export default {
    name: 'youtubevideo',
    props: ["embed"],
    methods: {
    getEmbedUrl: function() {
      return "https://www.youtube.com/embed/" + this.embed
    }
  }
}
</script>