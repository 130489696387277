<template>
    <div  class="learn-harp">
    <div v-show="getLang() == 'en'">
      <h1>Learn to play the harmonica</h1>
      <p class="learn-text">
        To be able to play songs on the harmonica is not that difficult! Here I give you everything you need to know to play any song listed on blowtabs.com!
      </p>
      <h2>Which harmonica?</h2>
      <p class="learn-text">
      The songs here are for the blues harp (diatonic harmonica). There are two types of harmonica available: Chromatic harmonicas have a small slider on the side and you can play notes in any key on them. Diatonic harmonicas do not have a slider and only have notes of a certain key, C for example. This is also the most common harmonica and therefore the songs here are tuned to C as well. If you have a harmonica in a different key, it doesn't matter, because the holes you have to play are always the same, the whole song just sounds a bit higher or lower.
      </p>
      <p class="learn-text">
      Some say the most expensive harmonica still costs less than the cheapest saxophone. I have had many harmonica for less than 10 bucks. Please don't do that. There may be some that still work well for little money, I just haven't experienced it yet. When I bought one between $20 and $30 I was stunned because it played so much better than the many cheap ones.
      </p>
      <h2How to play</h2>
      <p class="learn-text">
      Hold the harmonica as shown in the following video. It doesn't matter if you switch hands or not. It's all about giving the harmonica's sound more volume. As long as you hit the right holes, you can even hold it like a corncob. If the notes on the left sound lower than those on the right, you hold the harmonica the right way round. You can get two different notes from each hole. One when blowing in and one when drawing in the air.
      </p>
      <youtubevideo embed="ZK2L4ap4ZOA"></youtubevideo>
      <p class="learn-text">
      When you play it for the first time, you will probably play several notes at the same time. And that will always sound good! Because the notes are arranged so that you always play a chord (Richter tuning). But we want to play individual notes here. There are different possibilities for this:
      </p>
      <p class="learn-text">
      <b>The U-Blocking</b> is particularly suitable for beginners, because it does not fatigue the musculature of the mouth so fast. To do this, form a small U with your tongue and blow or draw on only one hole (which is actually called a cell). Don't be afraid to use your tongue to find the individual openings. After all, you play blind. Where which cell is, you learn bit by bit automatically. The disadvantage of U-blocking: The so-called bending is very difficult, but luckily we don't need it on blowtabs.com. This is what U-Blocking looks like:
      </p>
      <youtubevideo embed="eWNzEwZFcQE"></youtubevideo>
      <p class="learn-text">
      <b>The pucking</b> don't involves the tongue. You form a small hole with your lips similar to whistleling. The goal will be to master pucking (also called lipping), because with your tongue you can create effects. For example, you can make a tone sound deeper than it is supposed to. That's the bending.
      </p>
      <youtubevideo embed="6MKZeq490zs"></youtubevideo>
      <p class="learn-text">
      A little trick to play the notes better I call deh-deh-deh. You block the air with your tongue and release it again, as if you want to say "deh" but without using your voice. This makes it easier to distinguish individual notes if you have to play the same note several times in a row.
      </p>
      <youtubevideo embed="8xaCzDiTlCQ"></youtubevideo>
      <h2>The tab system, playing without notes</h2>
      <p class="learn-text">
      Reading notes can be quite exhausting and confusing. Because notes apply equally to all instruments. And because many people don't feel like learning notes there are tabs for many instruments. On the upper side of your harmonica, you will probably have a numbering of the comb from 1 to 10. And we use this numbering instead of notes. 4 means that you have to blow 4 in Kanzelle. And -6 means that you have to draw on the 6. The trick is to hit the right hole and not land too far to the left or right. You just have to practice that over and over again.
      </p>
      <h2>That's how you learn the songs</h2>
      <p class="learn-text">
      Now you know everything to get started! Choose from the many songs one that seems easy to you and try to play it a few times tone by tone without paying attention to the length of the notes. Then set the speed of the song to 50% or slower, turn off the sound and play with the song until you have hit all the notes. Increase the speed by 5% and repeat the whole thing until you have reached 100%. From this point on, you may not know the song by heart but at the right speed. You will learn it by heart by trying not to look at the tabs and getting as far as possible.
      </p>
      <p class="learn-text">
      Have fun!
      </p>
      <p class="learn-text">
      PS: Everything on this page is completely free and without advertising, so I would be happy if you support the project and me and donate me via <a href="http://paypal.me/letsgamedev/3" target="_blank">Paypal</a>, thank you!
      </p>
      <p class="learn-text">
      PPS: You can twitter problems, wishes and improvement suggestions with the hashtag <a target="_blank" href="https://twitter.com/search/?q=%23blowtabs&f=realtime">#blowtabs</a>, that's the best way for me to see them :)
      </p>
      </div>

      <div v-show="getLang() == 'de'">
      <h1>Mundharmonika lernen</h1>
      <p class="learn-text">
      <a v-on:click="showVideo('harpBigVideoDe')">Keine Lust auf lesen? Klappe hier das ganze als Video aus <i class="fas fa-level-down-alt"></i></a> 
      <div id="harpBigVideoDe">
        <youtubevideo embed="_jWI-01R_to"></youtubevideo>
        <p class="learn-text"><a target="_blank" href="https://amzn.to/2LkUSGd">Hier gibts eine gute Mundharmonika</a></p>
      </div>
      </p>
      <p class="learn-text">
        Lieder auf der Mundharmonika spielen zu können ist gar nicht so schwer! Hier gebe ich dir alles wichtige mit, was du brauchst um jedes Lied auf BlowTabs.com spielen zu können!
      </p>
      <h2>Welche Mundharmonika?</h2>
      <p class="learn-text">
        Die Lieder hier sind für die Bluesharp (Diatonische Mundharmonika) gedacht. Mundharmonikas kann man in zwei Varianten kaufen. Chromatische Mundharmonikas haben einen kleinen Schieber an der Seite und auf ihnen kann man Töne in jeder Tonart spielen. Diatonische Mundharmonikas haben keinen Schieber und besitzen grundsätzlich nur Töne einer bestimmten Tonart, C zum Beispiel. Das ist auch die häufigste Mundharmonika und darum sind die Lieder hier auch auf C gestimmt. Falls du eine Mundharmonika in einer anderen Tonart hast, ist das egal, denn die Löcher, die du anspielen musst sind immer die gleichen, das ganze Lied klingt dann nur etwas höher oder tiefer.
      </p>
      <p class="learn-text">
        Man sagt die teuerste Mundharmonika kostet immernoch weniger als das günstigste Saxophon. Ich habe in den letzten Jahren viele Mundharmonikas für unter 10€ gehabt. Mach das bitte nicht. Es mag einige geben, die für wenig Geld trotzdem gut funktionieren, ich habe es nur noch nicht erlebt. Als ich mir dann eine zwischen 20€ und 30€ kaufte war ich baff, weil sie sich so viel besser spielte als die vielen Billigen. Du kannst dir zum Beispiel diese hier holen: <a target="_blank" href="https://amzn.to/2LkUSGd">LINK</a>. Oder eine andere mit guten Bewertungen im gleichen Preissegment.
      </p>
      <h2>Wie man spielt</h2>
      <p class="learn-text">
        Halte die Mundharmonika wie im Folgenden Video gezeigt. Dabei ist es egal, ob du die Hände vertauscht oder nicht. Es geht nur darum, der Mundharmonika mehr Klangvolumen zu geben. Meinetwegen kannst du sie auch wie einen Maiskolben halten, solange du die richtigen Löcher triffst. Wenn die Töne auf der linken Seite tiefer klingen als auf der rechten, hältst du die Mundharmonika richtigherum. Du kannst aus jedem Loch zwei verschiedene Töne holen. Einen beim Reinpusten und einen beim Einziehen der Luft.
      </p>
      <youtubevideo embed="ZK2L4ap4ZOA"></youtubevideo>
      <p class="learn-text">
        Beim ersten Reinpusten wirst du wahrscheinlich mehrere Töne gleichzeitig spielen. Und das wird immer gut klingen! Denn die Töne sind so angeordnet (Richterstimmung) dass du immer einen Akkord spielst. Aber wir wollen hier ja gezielt einzelne Töne anspielen. Dafür gibt es verschiedene Möglichkeiten:
      </p>
      <p class="learn-text">
      <b>Das U-Blocking</b> ist besonders für Anfänger geeignet, da es die Mundmuskulatur nicht so schnell ermüden lässt. Dazu bildest du mit der Zunge ein kleines U und pustest oder ziehst so gezielt an nur einem Kanal (Kanzelle heißt das eigentlich). Scheue dich nicht mit der Zunge die einzellnen Öffnungen abzufahren um die richtige Kanzelle zu finden. Du spielst ja schließlich blind. Wo welches Loch ist, lernst du nach und nach automatisch. Der Nachteil des U-Blockings: Das sogenannte Bending ist sehr schwer, aber das brauchen wir zum Glück für kein einziges Lied auf BlowTabs.com. So sieht das U-Blocking aus:
      </p>
      <youtubevideo embed="eWNzEwZFcQE"></youtubevideo>
      <p class="learn-text">
      <b>Das Pucking</b> lässt die Zunge frei. Du bildest ähnlich wie beim Pfeiffen ein kleines Loch mit deinen Lippen, schiebst dir die Mundharmonika aber weiter in den Mund. Ziel wird es sein, irgendwann das Pucking (auch Lipping genannt) zu beherrschen, denn mit der Zunge kannst du so Effekte erzeugen. Zum Beispiel einen Ton tiefer klingen lassen als er eigentlich gedacht ist; das ist dann das Bending.
      </p>
      <youtubevideo embed="6MKZeq490zs"></youtubevideo>
      <p class="learn-text">
      Ein kleiner Trick um die Noten besser anspielen zu können nenne ich deh-deh-deh. Dabei blockst du die Luft mit der Zunge ung gibst sie wieder frei so als ob du "de" sagen willst aber ohne etwas zu sagen. So lassen sich einzelne Noten besser von einander unterscheiden, wenn mehrfach die gleiche hintereinander gespielt werden muss.
      </p>
      <youtubevideo embed="8xaCzDiTlCQ"></youtubevideo>
      <h2>Das Tabsystem, Spielen ohne Noten</h2>
      <p class="learn-text">
      Notenlesen kann ganz schön anstrengend und verwirrend sein. Denn Noten gelten für alle Instrumente gleichermaßen. Und weil viele Leute keine Lust haben zusätzlich noch Noten zu lernen gibt es für viele Instrumente sogenannte Tabs. Auf deiner Mundharmonika wirst du auf der Oberseite wahrscheinlich eine Durchnummerierung der Kanzellen haben, von 1 bis 10. Und diese Nummerierung nutzen wir anstelle von Noten. 4 bedeutet, dass du in Kanzelle 4 pusten sollst. Und -6 bedeutet, dass du an Kanzelle 6 ziehen musst. Die Kunst ist das zielsichere Treffen und nicht ein Loch zu weit links oder rechts zu landen. Das musst du einfach immer wieder üben.
      </p>
      <h2>So lernst du die Lieder</h2>
      <p class="learn-text">
      Jetzt weißt du alles, um anfangen zu können! Suche dir aus den vielen Liedern eines aus, das dir einfach erscheint und versuche es ein paar mal Ton für Ton nachzuspielen ohne auf die Länge der Noten zu achten. Danach stelle die Geschwindigkeit des Liedes auf 50% oder noch langsamer, schalte den Ton aus und spiele so lange mit dem Lied mit, bis du alle Töne getroffen hast. Erhöhe die Geschwindigkeit um 5% und wiederhole das ganze bist du bei 100% angelangt bist. Ab diesem Zeitpunkt, kannst du dann das Lied vielleicht noch nicht auswendig aber in der richtigen Geschwindigkeit. Auswendig lernst du es nun indem du versuchst nicht auf die Tabs zu sehen und so weit kommst wie möglich.
      </p>
      <p class="learn-text">
      Viel Spaß!
      </p>
      <p class="learn-text">
      PS.: Alles auf dieser Seite ist vollkommen kostenlos und ohne Werbung, daher würde ich mich freuen, wenn du das Projekt und mich untersützt und mir per <a href="http://paypal.me/letsgamedev/3" target="_blank">Paypal</a> den ein oder anderen Euro spendest, Danke dir!
      </p>
      <p class="learn-text">
      PPS.: Probleme, Wünsche und Verbesserungsvorschläge kannst du mit dem Hashtag <a target="_blank" href="https://twitter.com/search/?q=%23blowtabs&f=realtime">#blowtabs</a> twittern, so sehe ich sie am besten :)
      </p>
      </div>
  </div>
</template>

<script>
import youtubevideo from './youtubevideo'

export default {
    name: 'learnharp',
    components: {
        youtubevideo
    },
    methods: {
    showVideo: function() {
      var video = document.getElementById("harpBigVideoDe")
      console.log(video.style.height)
      if (video.style.height !== "465px") {
        video.style.height = "465px"
      } else {
        video.style.height = "0px"
      }
    },

    getLang: function() {
      return this.$root.selectedLang
    },
  }
}
</script>