<template>
    <div class="songfavolist-container">
    <h3>{{this.$root.L("youfavorites")}}</h3>
    <songentry v-for="n in favoList" v-bind:key="n.name" :songname="n.name" :songId="n.id" :strokeData="n.stroke"></songentry>
  </div>
</template>

<script>
import {Song} from '../songs'
import songentry from './songentry'

export default {
    name: 'songfavoslist',
    components: {
        songentry
    },
    props: ["songdata"],
    data: function () {
    return {
      sortKey: '',
      sortOrders: ''
    }
  },

  computed: {
    favoList: function () {
      return Song.filter(song => {
        return this.songdata.indexOf(song.id) != -1
      })
    }
  }

}
</script>