<template>
  <div class="header">
    <div class="title">
      <h1 class="titlename">BlowTabs.com</h1><br>
    </div>
    <div class="dropdown">
      <button class="dropbtn"><i class="fas fa-globe-americas"></i> {{getLang()}}</button>
      <div class="dropdown-content">
        <a v-on:click="changeLang('en')">English (UK)</a>
        <a v-on:click="changeLang('de')">Deutsch (DE)</a>
      </div>
    </div>
    <div class="menu-tabs-container">
      <div class="menu-tab tab-active" v-on:click="swap($event, 'play')">{{this.$root.L("play")}}</div>
      <div class="menu-tab" v-on:click="swap($event, 'songs')">{{this.$root.L("chooseSong")}}</div>
      <div class="menu-tab" v-on:click="swap($event, 'harp')">{{this.$root.L("harp")}}</div>
      <div class="menu-tab" v-on:click="swap($event, 'tin')">{{this.$root.L("tinWhistle")}}</div>
      <div class="menu-tab" v-on:click="swap($event, 'free')">{{this.$root.L("allfree")}}</div>
    </div>
  </div>
</template>

<script>
import {onResetBtn, onHeadMenu} from '../main'
import {Save} from '../save'
export default {
  name: 'headmenu',
  data() {
    return {
      sortKey: '',
      sortOrders: ''
    }
  },

  methods: {
    swap(button, key) {
      onResetBtn()
      this.$root.$emit('silentium')
      onHeadMenu(button.target, key)
    },

    getLang() {
      return this.$root.selectedLang === "de" ? "Deutsch (DE)" : "English (UK)"
    },

    changeLang(lang) {
      this.$root.selectedLang = lang
      Save.setSelectedLanguage(lang)
    }
  }
}
</script>