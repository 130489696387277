<template>
    <div class="tintabsbuttons">
    <div>C Tabs</div>
      <img class="tintabsbutton" src="../../assets/img/tin_tab_111111_editor.png" v-on:click="addNode('c4')"/>
      <img class="tintabsbutton" src="../../assets/img/tin_tab_111110_editor.png" v-on:click="addNode('d4')"/>
      <img class="tintabsbutton" src="../../assets/img/tin_tab_111100_editor.png" v-on:click="addNode('e4')"/>
      <img class="tintabsbutton" src="../../assets/img/tin_tab_111000_editor.png" v-on:click="addNode('f4')"/>
      <img class="tintabsbutton" src="../../assets/img/tin_tab_110000_editor.png" v-on:click="addNode('g4')"/>
      <img class="tintabsbutton" src="../../assets/img/tin_tab_100000_editor.png" v-on:click="addNode('a4')"/>
      <img class="tintabsbutton" src="../../assets/img/tin_tab_000000_editor.png" v-on:click="addNode('b4')"/>
      
      <img class="tintabsbutton" src="../../assets/img/tin_tab_011111+_editor.png" v-on:click="addNode('c5')"/>
      <img class="tintabsbutton" src="../../assets/img/tin_tab_111110+_editor.png" v-on:click="addNode('d5')"/>
      <img class="tintabsbutton" src="../../assets/img/tin_tab_111100+_editor.png" v-on:click="addNode('e5')"/>
      <img class="tintabsbutton" src="../../assets/img/tin_tab_111000+_editor.png" v-on:click="addNode('f5')"/>
      <img class="tintabsbutton" src="../../assets/img/tin_tab_110000+_editor.png" v-on:click="addNode('g5')"/>
      <img class="tintabsbutton" src="../../assets/img/tin_tab_100000+_editor.png" v-on:click="addNode('a5')"/>
      <!--<img class="tintabsbutton" src="../../assets/img/tin_tab_000000p.png" v-on:click="addNode('b5')"/>-->
  
      
      
      <div>D Tabs</div>
      <img class="tintabsbutton" src="../../assets/img/tin_tab_111111.png" v-on:click="addNode('d4')"/>
      <img class="tintabsbutton" src="../../assets/img/tin_tab_111110.png" v-on:click="addNode('e4')"/>
      <img class="tintabsbutton" src="../../assets/img/tin_tab_111100.png" v-on:click="addNode('f#4')"/>
      <img class="tintabsbutton" src="../../assets/img/tin_tab_111000.png" v-on:click="addNode('g4')"/>
      <img class="tintabsbutton" src="../../assets/img/tin_tab_110000.png" v-on:click="addNode('a4')"/>
      <img class="tintabsbutton" src="../../assets/img/tin_tab_100000.png" v-on:click="addNode('b4')"/>
      <img class="tintabsbutton" src="../../assets/img/tin_tab_011000.png" v-on:click="addNode('c5')"/>
      <img class="tintabsbutton" src="../../assets/img/tin_tab_000000.png" v-on:click="addNode('c#5')"/>

      <img class="tintabsbutton" src="../../assets/img/tin_tab_011111p.png" v-on:click="addNode('d5')"/>
      <img class="tintabsbutton" src="../../assets/img/tin_tab_111110p.png" v-on:click="addNode('e5')"/>
      <img class="tintabsbutton" src="../../assets/img/tin_tab_111100p.png" v-on:click="addNode('f#5')"/>
      <img class="tintabsbutton" src="../../assets/img/tin_tab_111000p.png" v-on:click="addNode('g5')"/>
      <img class="tintabsbutton" src="../../assets/img/tin_tab_110000p.png" v-on:click="addNode('a5')"/>
      <img class="tintabsbutton" src="../../assets/img/tin_tab_100000p.png" v-on:click="addNode('b5')"/>
  </div>
</template>

<script>
export default {
    name: 'tintabsbuttons',
    methods: {
    addNode: function(noteName) {
      document.getElementById("eNotes").value += noteName
    }
  },
}
</script>