export const Save = {
    getLastLearnedSongConfig: function() {
        return JSON.parse(localStorage.getItem("lastSong"))
    },

    setLastLearnedSongConfig: function(config) {
        localStorage.setItem("lastSong", JSON.stringify(config))
    },

    getInstrument: function() {
        return localStorage.getItem("instrument")
    },

    setInstrument: function(key) {
        localStorage.setItem("instrument", key)
    },
    
    getFavoriteIds: function() {
        return JSON.parse(localStorage.getItem("favoSongs"))
    },

    setFavoriteIds: function(idArray) {
        localStorage.setItem("favoSongs", JSON.stringify(idArray))
    },

    getSelectedLanguage: function() {
        return JSON.parse(localStorage.getItem("lang"))
    },

    setSelectedLanguage: function(lang) {
        localStorage.setItem("lang", JSON.stringify(lang))
    },

    deletEverything: function() {
        localStorage.removeItem('lastSong');
        localStorage.removeItem('instrument');
        localStorage.removeItem('favoSongs');
        localStorage.removeItem('lang');
    }
}