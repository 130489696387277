const Tone = require('../lib/Tone')
window.Tone = Tone
import {SampleLibrary} from '../lib/Tonejs-Instruments'
import {currentSong, 
	lengthToTime, 
	songPlayer, 
	setIsPlayAlong, 
	updateLastDate,
	activateNextNote,
	setCurrentProgressbar
} from './main'
import { detect } from'detect-browser'


export var SongPlayer = function() {
	const browser = detect();

	if (browser.name === "ie") {
		return {
			playNote: function(){},
			setPointer,
			setInstrument: function(){},
			setPlayBackSpeed,
			getPlayBackSpeed,
			stop: function(){}
		}
	}
	const configHarp = {
		"modulationIndex" : 12.22,
		"envelope" : {
			"attack" : 0.001,
			"decay" : 0.004
		},
		"modulation" : {
			"type" : "sawtooth"
		},
		"modulationEnvelope" : {
			"attack" : 0.002,
			"decay" : 0.001
		}
	}

	var toneHarp = new Tone.FMSynth(configHarp).toMaster();
	var toneTin = SampleLibrary.load({
		instruments: "flute",
		baseUrl: "./samples/"
		}).toMaster();

	var currentInstrument = null
	var playBackSpeed = 1
	var currentNote = ""

	function playNote(note, length) {
		currentNote = note
		var playLength = length * playBackSpeed
		currentInstrument.triggerAttackRelease(note,  playLength);
	}

	function setPointer(p) {
		pointer = p
	}

	function setInstrument(name) {
		console.log("HUI", name)
		switch (name) {
			case "TinC":
			case "TinD":
				currentInstrument = toneTin;
				break;
			default:
				currentInstrument = toneHarp;
				break;
		}
			
	}

	function setPlayBackSpeed(value) {
		playBackSpeed = value
	}

	function getPlayBackSpeed() {
		return playBackSpeed
	}

	function stop() {
		if (currentInstrument) {
			currentInstrument.triggerRelease([currentNote], 0.1)
		}
	}

	return {
		playNote,
		setPointer,
		setInstrument,
		setPlayBackSpeed,
		getPlayBackSpeed,
		stop
	}
}

var Tick = function() {
	var t = new Audio('/samples/tick.mp3')
	return function() {
		t.currentTime = 0
		t.play()
	}
}();

export function startStroking(skip) {
	var stroke = currentSong.stroke
	var count = stroke.split("/")[0]-0
	var noteLength = stroke.split("/")[1]-0

	var pauseTime = lengthToTime(noteLength) * songPlayer.getPlayBackSpeed()
	
	function timeTick() {
		count--
		//console.log("HUI", {currentNotePlayTime, currentNoteGoalTime})
		if (count == -1) {
			setIsPlayAlong(true)
			updateLastDate()
			activateNextNote()
			setCurrentProgressbar()
		} else {
			Tick()
			setTimeout(function() {
				timeTick()
			},pauseTime * 1030)
		}
	}

	if (skip) count = 0

	timeTick()

	console.log("HUI",stroke, count, noteLength)
	
}