<template>
    <div class="field is-horizontal">
    <div class="field-label is-normal"><label class="label">{{name}}</label></div>
    <div class="field-body"><div class="field"><div class="control">
        <input :id="id" class="input" type="text" :placeholder="name" :value="def">
    </div></div></div>
    </div>
</template>

<script>
export default {
    name: 'editorfield',
    props: ["name", "def", "id"],
}
</script>