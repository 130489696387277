<template>
    <div class="songentry">
    <button class="button songentry__play-button" v-on:click="playSong(songId)"><i v-show="plays === false" class="fa fa-play" aria-hidden="true"></i><i v-show="plays === true" class="fa fa-stop" aria-hidden="true"></i></button>
    <button class="button songentry__play-button" v-on:click="learn(songId)">{{this.$root.L("learn")}}</button>
    <!--<strokeIcon :count="getStrokeCount()" :length="getStrokeLength()"></strokeIcon>-->
    <div class="songlist-name">{{getSongName()}}</div>
    <img v-show="!isFavo(songId)" class="favo-star" src="../../assets/img/favo_no.png" v-on:click="setFavo(songId, true)" alt="add"/>
    <img v-show="isFavo(songId)" class="favo-star" src="../../assets/img/favo_yes.png" v-on:click="setFavo(songId, false)" alt="add"/>
  </div>
</template>

<script>
import {
  prelistenConfig,
   applyConfig,
   playSong,
   onResetBtn,
   config,
   onHeadMenu} from './../main'
import {Save} from './../save'

export default {
    name: 'songentry',
    props: ["songname", "strokeData", "songId"],
    data: function () {
        return {
            plays: false
        }
    },
    created: function() {
    this.$root.$on('silentium', this.silentium)
  },
  methods: {
    learn: function(t) {
        this.$root.$emit('silentium')
        config.id = t
        onHeadMenu( document.getElementsByClassName("menu-tab")[0], 'play')
    },
    getStrokeCount: function() {
      return this.strokeData.split("/")[0]
    },
    getStrokeLength: function() {
      return this.strokeData.split("/")[1]
    },
    isFavo: function(songId) {
      return this.$root.favosongs.indexOf(songId) != -1
    },
    getSongName() {
      if (typeof this.songname === "string") {
        return this.songname 
      } else {
        return this.songname[this.$root.selectedLang]
      }
    },
    setFavo: function(songId, addToFavo) {
      if (addToFavo && this.$root.favosongs.indexOf(songId) === -1) {
        this.$root.favosongs.push(songId)
      } else {
        this.$root.favosongs = this.$root.favosongs.filter(id => id !== songId)
      }
      Save.setFavoriteIds(this.$root.favosongs)
    },
    silentium: function() {
      if (this.plays) {
        this.playSong()
      }
    },
    playSong: function(id) {
      if (this.plays) {
        console.log("STOP SONG", id, this.plays)
        onResetBtn()
      } else {
        this.$root.$emit('silentium')
        prelistenConfig.id = id
        applyConfig(prelistenConfig)
        setTimeout(function() {playSong(true)}, 100)
      }
      this.plays = !this.plays      
    }
  }
}
</script>