<template>
    <div class="field is-horizontal">
    <div class="field-label is-normal"><label class="label">{{name}}</label></div>
    <div class="field-body"><div class="field"><div class="control">
        <textarea rows="4" cols="50" :id="id" class="input" type="text" :placeholder="name" :value="def"></textarea>
    </div></div></div>
    </div>
</template>

<script>
export default {
    name: 'editorfield2',
    props: ["name", "def", "id"],
}
</script>