<template>
    <div class="songContainer">
      <div class="songname">{{getSongName()}}</div>
      <audiobuttons></audiobuttons>
      <div class="tab-container">
        <stroke v-for="n in songdata" :songdata="n"></stroke>
      </div>
      <div style="clear:both"></div>
      <div class="spacer"></div>
    </div>
</template>

<script>
import audiobuttons from './audiobuttons'
import stroke from './stroke'

export default {
    name: 'song',
    components: {
        audiobuttons,
        stroke
    },
    props: ["songdata", "songname"],
    methods: {
    getSongName() {
      if (typeof this.songname === "string") {
        return this.songname 
      } else {
        return this.songname[this.$root.selectedLang]
      }
    }
  }
}
</script>