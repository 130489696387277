import 'babel-polyfill';
import {SongPlayer, startStroking} from './songplayer'
import {getParam} from './toolbox'
import {Save} from './save'
import {Song} from './songs'
import {
    isSongForTune, 
    extractNotesFromSong,
    getHarpsForKey,
    TIN_TABS,
    INSTRUMENTS,
    transposeSong
} from './notes'
import {time} from './toolbox'

var Icon = {
    play: '<i class="fa fa-play" aria-hidden="true"></i>',
    pause: '<i class="fa fa-pause" aria-hidden="true"></i>',
    reset: '<i class="fa fa-step-backward" aria-hidden="true"></i>',
    micIsOff: '<i class="fa fa-microphone-slash" aria-hidden="true"></i>',
    micIsOn: '<i class="fa fa-microphone" aria-hidden="true"></i>'
}

//UI objects
var songflag = null
var songTabs = null
var editor = null
var contentContainer = {}

var currentTabs = null
var pointer = -1
export var currentSong = null
var currentNotePlayTime = 0
var currentNoteGoalTime = 0
var isCurrendNotePlayed = false
var lastDate = 0
var cheatKey = false
var currentProgressBar = null
export var songPlayer = null
var isPlayAlong = false
var isShowSongs = false
var isMicOn = false

export var config = {
    id: 1,
    speed: 100,
    isLoop: true,
    isSound: true
}

export var prelistenConfig = {
    id: 1,
    speed: 100,
    isLoop: false,
    isSound: true
}

export function setIsPlayAlong(is) {
    isPlayAlong = is
}

export function updateLastDate() {
    lastDate = time()
}

export function initialiseSite() {
    addToContainerAs('songlist-container', "songList")
    addToContainerAs('songContainer', "song")
    addToContainerAs('instrumenthub', "instrumentHub")
    addToContainerAs("learn-harp", 'learnHarp')
    addToContainerAs("learn-tin", 'learnTin')
    addToContainerAs("free", 'free')
    addToContainerAs("impressum", 'impressum')
    addToContainerAs("songfavolist-container", 'songfavolistContainer')

    songTabs = document.getElementById('songTabs')
    editor = document.getElementById('editor')
    init()

}

function addToContainerAs(className, as) {
    contentContainer[as] = document.getElementsByClassName(className)[0]

}

function init() {
    songPlayer = SongPlayer()
    setInterval(checkNote, 1 / 30 * 1000)
    if (getParam("editor") - 0 === 1) addEditor()

    if (Save.getInstrument() != null) {
        app.currentInstrument = Save.getInstrument()
    }

    if (Save.getFavoriteIds()) {
        app.favosongs = Save.getFavoriteIds()
    }

    if (Save.getLastLearnedSongConfig() != null) {
        config = Save.getLastLearnedSongConfig()
    }
    onHeadMenu(document.getElementsByClassName("menu-tab")[0], 'play')
}

export function applyConfig(config) {
    try {
        chooseSong(config.id)
        document.getElementById("speedSlider").value = config.speed
        updateSpeedSlider()
        document.getElementById("soundCheckBox").checked = config.isSound
        document.getElementById("loopCheckBox").checked = config.isLoop
    } catch (e) {
        console.error(e)
    }
}

window.addEditor = function() {
    console.log("Editor activated")
    editor.style.display = "block"
}

function prepareSong(song) {
    currentSong = song
    app.name = currentSong.name
    var raw = song.string
    var tabs = raw.split('|')
    console.log(tabs)
    var noteBlocks = tabs.map((t) => t.split(';'))

    var notes = noteBlocks.map(createNoteObjects)
    var simpleHarpTabs = notes.reduce((sum, val) => sum.concat(val), [])
    for (var i = 0; i < notes.length; i++) {
        var block = notes[i]
        for (var j = 0; j < block.length; j++) {
            block[j].text = ""
        }
    }

    currentSong = {
        name: song.name,
        stroke: song.stroke,
        tabs: notes,
        instrument: song.instrument,
        bpm: song.bpm,
        key: song.key,
        notes: noteBlocks,
        simpleTabs: simpleHarpTabs,
    }

    app.song = currentSong.tabs
    setTimeout(setFirstStrokeSpacing, 10)
    return currentSong
}

function showWarning(text) {
    console.warn(text)
}

function createNoteObjects(block) {
    return block.map((raw) => {
        if (raw[0] == null) return

        var flatSharpOffset = 0
        var o = {}
        if (raw[1] === "#" || raw[1] === "b") {
            o.note = raw[0].toUpperCase() + raw[1] + raw[2]
            flatSharpOffset++
        } else {
            try {
                o.note = raw[0].toUpperCase() + raw[1]
            } catch (e) {
                console.log(o, block)
                throw e
            }
        }

        o.text = "???"
        o.dotScale = 1
        var lengthString = raw.substr(3 + flatSharpOffset)

        if (lengthString[lengthString.length - 1] == "+") {
            o.isLongNote = true
            lengthString = lengthString.substr(0, lengthString.length - 1)
        }

        if (lengthString[lengthString.length - 1] == "3") {
            o.dotScale = 2/3
            lengthString = lengthString.substr(0, lengthString.length - 1)
        }

        if (lengthString.indexOf("..") != -1) {
            o.dotScale = 1.75
            lengthString = lengthString.substr(0, lengthString.length - 2)
        } else if (lengthString.indexOf(".") != -1) {
            o.dotScale = 1.5
            lengthString = lengthString.substr(0, lengthString.length - 1)
        }



        o.value = lengthString
        o.lengthInSeconds = lengthToTime(o.value) * o.dotScale
        o.lengthInPixel = (60 / (60.0 / currentSong.bpm)) * o.lengthInSeconds


        var isPause = raw.substr(0, 2).toUpperCase() === 'PA'
        if (isPause) {
            o.channel = "-"
        } else {
            switch (app.currentInstrument) {
                case "TinC":
                case "TinD":
                    var image = "<img class='tin-tab' src='img/tin_tab_{0}.png'/>".replace("{0}", createTinTabString(o.note, app.currentInstrument))
                    if (o.isLongNote) image = "<img class='tin-tab' src='img/tin_tab_no.png'/>"
                    o.channel = image
                    break;
                case "HarpC":
                    o.channel = getHarpsForKey(currentSong.key).harpNotes.indexOf(o.note)
                    o.channel = o.channel < 10 ? o.channel + 1 : -o.channel + 9
                    if (o.isLongNote) o.channel = " "
                    break;
            }
        }

        return o
    })
}

export function lengthToTime(length) {
    var beatTimeInSeconds = 60.0 / currentSong.bpm
    var noteValue = Number.parseInt(length)
    var possibles = [1, 2, 4, 8, 16, 32]
    var converts = [4, 2, 1, 0.5, 0.25, 0.125]
    var multiplier = converts[possibles.indexOf(noteValue)]
    return beatTimeInSeconds * multiplier

}

function createTinTabString(note, instrumentName) {
    return TIN_TABS[instrumentName][INSTRUMENTS[instrumentName].indexOf(note)]
}

function checkNote() {
    if (currentTabs && pointer > -1) {
        isCurrendNotePlayed = isPlayAlong
        updateSong()
    }
}

function updateSong() {
    var newtime = time()
    var difTime = newtime - lastDate
    lastDate = newtime
    if (isCurrendNotePlayed) {
        currentNotePlayTime += difTime
        if (currentNotePlayTime >= currentNoteGoalTime) {
            currentNotePlayTime = currentNoteGoalTime
            updateProgessBar()
            activateNextNote()
            setCurrentProgressbar()
        } else {
            updateProgessBar()
        }
    }
}

function setFirstStrokeSpacing() {
    var tabContainer = document.getElementsByClassName("tab-container")[0]
    var firstStrokeWidth = tabContainer.getElementsByClassName('stroke')[0].offsetWidth
    var secondStrokeWidth = tabContainer.getElementsByClassName('stroke')[1].offsetWidth

    var space = Math.max(0, secondStrokeWidth - firstStrokeWidth + 1)
    //console.log(space, firstStrokeWidth, secondStrokeWidth)
    tabContainer.getElementsByClassName('stroke')[0].style["margin-left"] = space + "px"
}

function updateProgessBar() {
    currentProgressBar.style.width = 100 * (currentNotePlayTime / currentNoteGoalTime) + "%"
}

export function setCurrentProgressbar() {
    if (pointer === -1) return
    var bars = document.getElementsByClassName("song-bar")
    if (pointer > 0) {
        currentProgressBar.classList.add("old")
        currentProgressBar.classList.remove("pause")
    }
    currentProgressBar = bars[pointer]
    if (currentSong.simpleTabs[pointer].note.toUpperCase() === 'PA') currentProgressBar.classList.add("pause")
}

function resetProgressBars() {
    var bars = document.getElementsByClassName("song-bar")
    for (var i = 0; i < bars.length; i++) {
        bars[i].classList.remove("pause")
        bars[i].classList.remove("old")
        bars[i].style.width = "0%"
    }
}

export function activateNextNote() {
    pointer++

    if (pointer === currentTabs.length) {
        songPlayer.stop()
        if (isLoopOn()) {
            pointer = 0
            resetProgressBars()
        } else {
            pointer--
            setTimeout(onResetBtn, 100)
            return
        }
    }

    if (isMusicOn() && isPlayAlong && currentSong.simpleTabs[pointer].note.toUpperCase() !== 'PA') {
        var tab = currentSong.simpleTabs[pointer]
        songPlayer.playNote(tab.note, tab.lengthInSeconds + sameNotePadding())
    }
    var noteLengthPerBeat = currentSong.stroke.split("/")[1] - 0
    var milliSecondsPerBeat = 60000 / currentSong.bpm
    currentNoteGoalTime = (milliSecondsPerBeat / (currentSong.simpleTabs[pointer].value / noteLengthPerBeat)) * currentSong.simpleTabs[pointer].dotScale
    currentNoteGoalTime *= songPlayer.getPlayBackSpeed()
    currentNotePlayTime = 0

}

function sameNotePadding() {
    try {
        if (currentSong.simpleTabs[pointer + 1].isLongNote) return 0.1
        if (currentSong.simpleTabs[pointer].note == currentSong.simpleTabs[pointer + 1].note) return -0.1
    } catch (e) {
        return 0
    }
    return 0
}

export function playSong(force) {
    var btn = document.getElementById('playBtn')
    if (!isPlayAlong) {
        btn.innerHTML = Icon.pause
        if (pointer === 0) {
            pointer = -1
            startStroking(force)
        } else {
            isPlayAlong = true
            activateNextNote()
            setCurrentProgressbar()
        }

    } else {
        btn.innerHTML = Icon.play
        isPlayAlong = false

        pointer--
        setCurrentProgressbar()
        var bars = document.getElementsByClassName("song-bar")
        bars[pointer + 1].classList.remove("pause")
        bars[pointer + 1].classList.remove("old")
    }
}

export function onResetBtn() {
    var btn = document.getElementById('playBtn')
    songPlayer.stop()
    if (isPlayAlong) playSong()
    isPlayAlong = false
    pointer = -1
    try {
        resetProgressBars()
        activateNextNote()
        setCurrentProgressbar()
    } catch (e) {
        console.error(e)
    }

}

function chooseSong(id) {
    var s = Song.find(v => v.id === id)
    if (s) {
        var isDifferentKey = app.currentInstrument[app.currentInstrument.length - 1] !== s.key
        var songCopy = {
            name: s.name,
            instrument: ["TinC", "TinD", "HarpC"],
            bpm: s.bpm,
            stroke: s.stroke,
            key: app.currentInstrument[app.currentInstrument.length - 1],
            string: isSongForTune(extractNotesFromSong(s), app.currentInstrument) ? s.string : transposeSong(s.string, s.key),
            text: s.text
        }
        prepareSong(songCopy)
        currentTabs = currentSong.simpleTabs

        if (songPlayer == null) init()
        songPlayer.setInstrument(app.currentInstrument)
        initialiseSongPlay()
    }
}

function initialiseSongPlay() {
    setTimeout(function () {
        onResetBtn()
    }, 10)
}

export function updateSpeedSlider() {
    var value = document.getElementById("speedSlider").value
    document.getElementById("speedValue").innerHTML = "Speed: " + value + "%"
    songPlayer.setPlayBackSpeed(100 / value)
}

function isMusicOn() {
    return document.getElementById("soundCheckBox").checked
}

function isLoopOn() {
    return document.getElementById("loopCheckBox").checked
}

export function onLoopSwitch() {
    config.isLoop = isLoopOn()
}

export function onMusicSwitch() {
    config.isSound = isMusicOn()
}

export function onHeadMenu(element, key) {
    console.log(element, key)
    var tabs = document.getElementsByClassName("menu-tab")
    for (let i = 0; i < tabs.length; i++) {
        tabs[i].classList.remove("tab-active")
    }

    if (key !== "impressum")
        element.classList.add("tab-active")

    activateContent(key)
}

function activateContent(key) {
    var contents = Object.keys(contentContainer)
    
    contents.map((k) => contentContainer[k].classList.add("invisible"))

    switch (key) {
        case "play":
            contentContainer.song.classList.remove("invisible")
            contentContainer.songfavolistContainer.classList.remove("invisible")
            applyConfig(config)
            Save.setLastLearnedSongConfig(config)
            break;
        case "songs":
            contentContainer.songList.classList.remove("invisible")
            contentContainer.instrumentHub.classList.remove("invisible")
            break;
        case "free":
            contentContainer.free.classList.remove("invisible")
            break;
        case "harp":
            contentContainer.learnHarp.classList.remove("invisible")
            break;
        case "tin":
            contentContainer.learnTin.classList.remove("invisible")
            break;
        case "impressum":
            contentContainer.impressum.classList.remove("invisible")
            break;
    }
}

export function loadEditorSong() {
    function get(id) {
        return document.getElementById(id).value
    }

    console.log(get('eNotes').replace(/\s/g, ''))
    prepareSong({
        name: "Test song",
        bpm: get('eBpm') - 0,
        stroke: get('eStroke'),
        instrument: app.currentInstrument,
        key: get('eKey'),
        string: convertHarpTabsToNotes(), //get('eNotes').replace(/\s/g, ''),
        text: ""
    })
    currentTabs = currentSong.simpleTabs

    initialiseSongPlay()
}

export function convertHarpTabsToNotes() {
    var raw = document.getElementById("eNotes").value.replace(/\s/g, '').replace(/;\|/g, '|')
    var trans = {
        "+1": "c3",
        "+2": "e3",
        "+3": "g3",
        "+4": "c4",
        "+5": "e4",
        "+6": "g4",
        "+7": "c5",
        "+8": "e5",
        "+9": "g5",
        "+X": "c6",

        "-1": "d3",
        "-2": "g3",
        "-3": "b3",
        "-4": "d4",
        "-5": "f4",
        "-6": "a4",
        "-7": "b4",
        "-8": "d5",
        "-9": "f5",
        "-X": "a5",

        "q": "/1",
        "w": "/2",
        "r": "/4",
        "t": "/8",
        "z": "/16",
        "u": "/32"
    }

    Object.keys(trans).map(key => {
        raw = raw.split(key).join(trans[key])
    })

    console.log(raw)
    return raw
}