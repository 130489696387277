<template>
    <div class="learn-tin">
    <div v-show="getLang() == 'en'">
      <h1>Learn to play the Tin Whislte</h1>
      <p class="learn-text">
      You want to learn the Tin Whislte (also called penny whistle)? A good choice, because a tin whistle is cheap and easy to play. Almost all songs on blowtabs.com can be played with a tin whistle in the key of C or D.<br>
      I will teach you everything you need to know here!
      </p>
      <h2>Which tin whistle?</h2>
      <p class="learn-text">
      Normally, when learning an instrument, you use the key C(-major), as most beginner songs are written in C.<br>
      But folk songs written for the Tin Whistle are usually in the key of D or G. The special thing about the tin whistle in D is that you can also play songs written in G.<br>
      Maybe you are not interested in Irish folk songs or you want to play songs that everyone knows, then you can also get a tin whistle in C.
      </p>
      <p class="learn-text">
      If a song is actually in C, but you have a tin whistle in D, that shouldn't be a problem, because I have almost all songs in both keys here :)
      </p>
      <h2>How to play</h2>
      <p class="learn-text">
      The tin whistle has two ends. You have to blow into one of them. If there is no sound, it was the wrong end. Place the index, middle and ring finger of your left hand on the three upper holes. In this case, the top is where your mouth is. The index, middle and ring fingers of your right hand cover the lower three holes. The thumbs support the flute from below and the little finger of the right hand supports the flute from above. I'll show you that again in the following video for safety.
      </p>
      <youtubevideo embed="2Nqv9oywExg"></youtubevideo>
      <p class="learn-text">
      You can swap hands too. The flute doesn't care. But try to stick to one grip if possible.
      </p>
      <p class="learn-text">
      If you now blow carefully into the tin whistle and all holes are well covered by your fingers, you will hear the deepest note. Now raise the lowest ring finger and blow again. You now play the next higher note on the scale. And so it goes on until all fingers are lifted. Pretty simple, isn't it? There are very few notes that deviate from this pattern.<br>
      If you want to play the high notes, you simply have to blow stronger into your tin whistle. This is called overblowing and allows you to play an octave higher. You can blow even stronger and play even higher. But this is a bit more absurd and you will hardly find it on this page. In the following video I played those higher notes and yes, this can be unpleasant.
      </p>
      <youtubevideo embed="xwqj4tpIyuo"></youtubevideo>
      <p class="learn-text">
      A little trick that will make it easier for you to play the notes is to hold back the air with your tongue. You don't have to hold your breath with your diaphragm between the notes, but clog the flow of air behind your teeth with your tongue. A bit like saying deh-deh-deh-deh but without using your voice. The video should help you!
      </p>
      <youtubevideo embed="SIgrCqaxj-0"></youtubevideo>
      <p class="learn-text">
      You can use your tongue to create various effects. Just play around a little bit. There has to be a bit of silliness.
      </p>
      <h2>The tab system, playing without notes</h2>
      <p class="learn-text">
      Reading notes can be quite exhausting and confusing. Because notes apply equally to all instruments. And because many people don't feel like learning notes there are tabs for many instruments. In the case of the tin whistle, these are small pictures that tell you which holes to cover and how hard to blow. White circles mean that the holes are open and black circles mean that you cover them with your fingers. If you see a small plus sign below it means that you have to go into the second octave. Here are the notes for the key of C and D, represented as real music notes and below as tabs.
      </p>
      <img class="learnimg" src="../../assets/img/tonesc.png"/>
      <img class="learnimg" src="../../assets/img/tonesd.png"/>
      <h2>That's how you learn the songs</h2>
      <p class="learn-text">
      Now you know everything to get started! Choose from the many songs one that seems easy to you and try to play it a few times tone by tone without paying attention to the length of the notes. Then set the speed of the song to 50% or slower, turn off the sound and play with the song until you have hit all the notes. Increase the speed by 5% and repeat the whole thing until you have reached 100%. From this point on, you may not know the song by heart but at the right speed. You will learn it by heart by trying not to look at the tabs and getting as far as possible.
      </p>
      <p class="learn-text">
      Have fun!
      </p>
      <p class="learn-text">
      PS: Everything on this page is completely free and without advertising, so I would be happy if you support the project and me and donate me via <a href="http://paypal.me/letsgamedev/3" target="_blank">Paypal</a>, thank you!
      </p>
      <p class="learn-text">
      PPS: You can twitter problems, wishes and improvement suggestions with the hashtag <a target="_blank" href="https://twitter.com/search/?q=%23blowtabs&f=realtime">#blowtabs</a>, that's the best way for me to see them :)
      </p>
    </div>

    <div v-show="getLang() == 'de'">
      <h1>Die Irische Blechflöte lernen</h1>
      <p class="learn-text">
      <a v-on:click="showVideo('tinBigVideoDe')">Keine Lust auf lesen? Klappe hier das ganze als Video aus <i class="fas fa-level-down-alt"></i></a> 
      <div id="tinBigVideoDe">
        <youtubevideo embed="eOAlG7kjY8w"></youtubevideo>
        <p class="learn-text"><a target="_blank" href="https://amzn.to/2FxyCVF">Blechflöte in C</a></p>
        <p class="learn-text"><a target="_blank" href="https://amzn.to/2JOLvNZ">Blechflöte in D</a></p>
      </div>
      </p>
      <p class="learn-text">
        Du hast Lust die Irische Blechflöte zu lernen? Eine gute Wahl, denn eine Blechflöte ist günstig und leicht zu spielen. Fast alle Lieder auf blowtabs.com kannst du mit einer Blechflöte in der Tonart C oder D spielen.<br>
        Ich werde dir hier alles, was du wissen musst beibringen!
      </p>
      <h2>Welche Blechflöte?</h2>
      <p class="learn-text">
        Normalerweise, wenn man ein Instrument lernt, greift man zur Tonart C(-Dur), da die meisten Anfängerlieder in C geschrieben sind. <br>
        Nun ist es aber so, dass Volkslieder, die für die Irische Blechflöte geschrieben wurden eher in D oder in G sind. Das besondere an der Blechflöte in D ist, dass du auch Lieder spielen kannst die in G geschrieben sind. <br>
        Vielleicht interessieren dich Irische Volkslieder aber auch gar nicht so sehr oder du möchtest eher Lieder spielen, die auch bei uns sehr bekannt sind, dann kannst du dir auch eine Blechflöte in C besorgen. 
      </p>
      <p class="learn-text">
        Wenn ein Lied eigentlich in C ist, du aber eine Blechflöte in D hast, sollte das aber gar kein Problem sein, denn ich habe so gut wie alle Lieder in beiden Tonarten hier :)
      </p>
      <p class="learn-text">
      Ich persönlich empfehle dir <a target="_blank" href="https://amzn.to/2JOLvNZ">diese Blechflöte in D</a> oder <a target="_blank" href="https://amzn.to/2FxyCVF">diese Blechflöte in C</a>. Keine von ihnen sollte dich mehr als 20€ kosten. Es gibt Flöten auch für bereits 5€ aber die machen oft Probleme in den hohen Tönen.
      <p class="learn-text">
      <a target="_blank" href="https://amzn.to/2JOLvNZ">Die Blechflöte in D</a>, die ich dir vorschlage, lässt sich sehr leicht spielen, du benötigst also nur sehr wenig Luft. Das ist praktisch um auch die hohen Töne leicht anspielen zu können, allerdings muss man bei den tiefen Tönen darauf achten nur sehr wenig Luft zu nutzen. Dennoch ist dies meine Lieblingsblechflöte :D
      </p>
      <p class="learn-text">
      <a target="_blank" href="https://amzn.to/2FxyCVF">Die Blechflöte in C</a> benötigt mehr Luft und verzeiht so in den tieferen Tönen mehr. Du kannst da also etwas wilder drauflospusten. Dafür benötigst du sehr viel mehr Atem und Druck um in die hohen Töne zu kommen.
      </p>
      <p class="learn-text">
      Das klingt schlimmer als es ist, glaub mir. Im vergleich mit anderen Instrumenten ist das wirklich nicht der Rede wert. So oder so wirst du den Dreh schnell raushaben.
      </p>
      <h2>Wie man spielt</h2>
      <p class="learn-text">
      Die Blechflöte hat zwei Enden. In eines musst du reinpusten. Kommt kein Ton, war es das falsche Ende. Den Zeige-, Mittel- und Ringfinger deiner Linken hand legst du auf die drei oberen Löcher. Oben ist in dem Fall dort, wo dein Mund ist. Der Zeige-, Mittel- und Ringfinger der rechten Hand verdecken die unteren drei Löcher. Die Daumen stützen die Flöte von unten und der kleine Finger der rechten Hand stützt die Flöte von Oben. Das zeige ich dir hier im folgenden Video zur Sicherheit noch einmal.
      </p>
      <youtubevideo embed="2Nqv9oywExg"></youtubevideo>
      <p class="learn-text">
      Du kannst die Hände auch tauschen. Der Flöte ist das egal und mir erst recht. Aber versuche möglichst bei einer Griffweise zu bleiben.
      </p>
      <p class="learn-text">
      Pustest du nun vorsichtig in die Blechflöte und alle Löcher sind von deinen Fingern gut abgedeckt, erklingt der tiefste Ton. Nun nimm den untersten Ringfinger hoch und puste wieder. Du spielst nun den nächsthöheren Ton auf der Tonleiter. Und so geht das immer weiter bis alle Finger angehoben sind. Ziemlich simpel oder? Es gibt nur sehr wenige Töne, die von diesem Schema abweichen.<br>
      Wenn du in die hohen Töne willst, musst du einfach stärker in deine Blechflöte pusten. Das wird Overblowing genannt und ermöglicht dir so eine Oktave höher zu spielen. Du kannst sogar noch stärker Pusten und so noch höher spielen. Das ist aber schon etwas unsinniger und wirst du hier auf der Seite kaum vorfinden. Im folgendem Video habe ich dir das mal vorgespielt und ja, die hohen Töne können unangenehm werden.
      </p>
      <youtubevideo embed="xwqj4tpIyuo"></youtubevideo>
      <p class="learn-text">
      Ein kleiner Trick, der dir das Anspielen der Noten erleichtern wird, ist das zurückhalten der Luft mit der Zunge. Dabei musst du zwischen den Noten den Atem nicht mit deinem Zwerchfell halten sondern verstopfst den Atemfluss hinter den Zähnen mit deiner Zunge. Ein bisschen so als würdest du deh-deh-deh-deh-deh sagen wollen aber ohne dein Stimmbänder zu nutzen. Das Video sollte dir dabei helfen!
      </p>
      <youtubevideo embed="SIgrCqaxj-0"></youtubevideo>
      <p class="learn-text">
      Du kannst mit der Zunge verschiedene Effekte hinbekommen. Probiere dich einfach ein bisschen aus. Ein bisschen Blödsinn muss auch mal sein.
      </p>
      <h2>Das Tabsystem, Spielen ohne Noten</h2>
      <p class="learn-text">
      Notenlesen kann ganz schön anstrengend und verwirrend sein. Denn Noten gelten für alle Instrumente gleichermaßen. Und weil viele Leute keine Lust haben zusätzlich noch Noten zu lernen gibt es für viele Instrumente sogenannte Tabs. Das sind im Fall der Blechflöte kleine Bilder, die dir sagen welche Löcher du zuhalten sollst und wie doll du pusten musst. Die Tabs habe ich im oberen Video bereits eingeblendet. Weiße Kreise bedeuten, dass die Löcher offen sind und Schwarze, dass du sie mit den Fingern verdeckst. Wenn du unterhalb ein kleines Plus siehst, heißt das, dass du in die zweite Oktave gehen musst. Hier noch die Noten für C und D, dargestellt als echte Musiknoten und darunter als Tabs.
      </p>
      <img class="learnimg" src="../../assets/img/tonesc.png"/>
      <img class="learnimg" src="../../assets/img/tonesd.png"/>
      <h2>So lernst du die Lieder</h2>
      <p class="learn-text">
      Jetzt weißt du alles, um anfangen zu können! Suche dir aus den vielen Liedern eines aus, das dir einfach erscheint und versuche es ein paar mal Ton für Ton nachzuspielen ohne auf die Länge der Noten zu achten. Danach stelle die Geschwindigkeit des Liedes auf 50% oder noch langsamer, schalte den Ton aus und spiele so lange mit dem Lied mit, bis du alle Töne getroffen hast. Erhöhe die Geschwindigkeit um 5% und wiederhole das ganze bist du bei 100% angelangt bist. Ab diesem Zeitpunkt, kannst du dann das Lied vielleicht noch nicht auswendig aber in der richtigen Geschwindigkeit. Auswendig lernst du es nun indem du versuchst nicht auf die Tabs zu sehen und so weit kommst wie möglich.
      </p>
      <p class="learn-text">
      Viel Spaß!
      </p>
      <p class="learn-text">
      PS.: Alles auf dieser Seite ist vollkommen kostenlos und ohne Werbung, daher würde ich mich freuen, wenn du das Projekt und mich untersützt und mir per <a href="http://paypal.me/letsgamedev/3" target="_blank">Paypal</a> den ein oder anderen Euro spendest, Danke dir!
      </p>
      <p class="learn-text">
      PPS.: Probleme, Wünsche und Verbesserungsvorschläge kannst du mit dem Hashtag <a target="_blank" href="https://twitter.com/search/?q=%23blowtabs&f=realtime">#blowtabs</a> twittern, so sehe ich sie am besten :)
      </p>
    </div>
  </div>
</template>

<script>
import youtubevideo from './youtubevideo'

export default {
    name: 'learntinwhistle',
    components: {
        youtubevideo
    },
    methods: {
    showVideo: function() {
      var video = document.getElementById("tinBigVideoDe")
      console.log(video.style.height)
      if (video.style.height !== "515px") {
        video.style.height = "515px"
      } else {
        video.style.height = "0px"
      }
    },

    getLang: function() {
      return this.$root.selectedLang
    },
  }
}
</script>