<template>
    <div class="stroke">
      <note v-for="n in songdata" :channel=n.channel :text="n.text" :lengthInPixel="n.lengthInPixel"></note>
  </div>
</template>

<script>
import note from './note'
export default {
    name: 'stroke',
    components: {
        note
    },
    props: ["songdata"]
}
</script>