<template>
    <div>  
      <div v-show="getLang() == 'en'">
        <footer><div id="footer"><a class="impressum" v-on:click="onHeadMenu(this, 'impressum')" style="color:white;">imprint & privacy</a></div></footer>
      </div>
      <div v-show="getLang() == 'de'">
        <footer><div id="footer"><a class="impressum" v-on:click="onHeadMenu(this, 'impressum')" style="color:white;">impressum & datenschutz</a></div></footer>
      </div>
    </div>
</template>

<script>
import {onHeadMenu} from './../main'
export default {
    name: 'privacy',
    methods: {
        getLang: function() {
            return this.$root.selectedLang
        },
        onHeadMenu
    }
}
</script>