<template>
    <div :class='"noteContainer"' :style='"width: "+lengthInPixel+"px;"'>
        <div class="note"><span class="song-bar"></span><span class="song-bar-back"></span><div v-html="channel"></div></div>
        <div class="songText">{{text}}</div>
    </div>
</template>

<script>
export default {
    name: 'note',
    props: ["channel", "text", "lengthInPixel"]
}
</script>